import React from "react";
import Loading from './components/Loading';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/cropImage';
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import AccountContentHeader from './components/AccountContentHeader';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import sprite from './media/icons.svg';
import user_img from './media/user.svg';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { IMaskInput } from 'react-imask';
function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString.split('.').reverse().join('.'));
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
function getAgeSuffix(age, translation) {
  if (age === 0) {
    return translation('yearLabel-Years');
  } else if (age % 10 === 1 && age % 100 !== 11) {
    return translation('yearLabel-Year');
  } else if ((age % 10 >= 2 && age % 10 <= 4) && !(age % 100 >= 12 && age % 100 <= 14)) {
    return translation('yearLabel-YearsSecond');
  } else {
    return translation('yearLabel-Years');
  }
}
const customStyles = {
  control: () => ({
    minHeight: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
};
const customStylesError = {
  control: () => ({
    minHeight: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
function validateEmail(email) {
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}
function validatePassword(password) {
    let onlylatin = /[^A-Za-z0-9]/.test(password);
    return onlylatin;
}
class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token(),
      roles: [],
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      userData: [],
      isLoaded: false,
      modalInfo: false,
      modalResponsibilities: false,
      modalContacts: false,
      shortInfoData: {},
      responsibilitiesData: {},
      contactsData: {},
      modalEditLoading: false,
      successSubmit: false,
      errorSubmit: null,
      isLoadingPhoto: false,
      avatarScale: false,
      modalAuth: false,
      date: new Date(),
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      dateModal: false,
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      birthdayDate: null,
      rolesUser: [],
      errors: null,
      error: false,
      birthdayDateValue: null,
      modalPhoto: false,
      formDataPhoto: null,
      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 2,
      aspect: 1 / 1,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      cropImageStatus: false,
      imagePhotoName: '',
      emailAuth: '',
      passwordAuth: '',
      changePass: false,
      errorFields: false,
      errorEmail: false,
      errorPassword: false,
      errorPasswordLength: false,
      errorDateBirth: false,
      errorLengthName: false,
      errorLengthLastName: false,
      errorLengthMiddleName: false,
      errorLengthPosition: false,
      errorName: false,
      errorMiddleName: false,
      errorLastName: false,
      errorPosition: false,
      modalPassword: false,
      currentPassValue: '',
      newPassValue: '',
      rePassValue: '',
      passwordHideCurrent: true,
      passwordHideNew: true,
      passwordHideRe: true,
      birthdayDateValueCount: -1,
      birthdayDateValueCountPress: 0,
      errorCodeChangeEmail: null,
      errorRolesEmpty: false,
      errorFormatNumber: false
    }
  }

  onCropChange = (crop) => {
    this.setState({ crop: crop, cropImageStatus: true })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels })
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom: zoom })
  }

  handleModalDisabled = () => {
    document.querySelector('body').style.overflow = "";
    this.setState(prevState => ({
      modalDisabled: true,
      contactsData: {}
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalInfo: false,
        modalResponsibilities: false,
        modalAuth: false,
        modalContacts: false,
        modalPhoto: false,
        modalDisabled: false,
        formDataPhoto: null,
        imagePhotoName: '',
        modalPassword: false
      }));
      if(this.state.rolesUser){
        this.setState(prevState => ({
          rolesUser: []
        }));
      }
    }, 500);
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerModalInfo() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/short-info`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState(prevState => ({
              shortInfoData: {
                lastName: data.lastName,
                middleName: data.middleName,
                name: data.name,
                userID: data.userID
              }
            }));
          });
          that.setState(prevState => ({
            modalInfo: !prevState.modalInfo
          }));
        }
      })
    })
  }
  handlerName(event) {
    const inputValue = event.target.value;
    if(this.state.errorName){
      this.setState(prevState => ({
        errorName: false
      }));
    }
    if (inputValue.length <= 20) {
      this.setState(prevState => ({
        shortInfoData: { ...prevState.shortInfoData, name: inputValue },
        errorLengthName: false
      }));
    } else {
      this.setState(prevState => ({
        errorLengthName: true
      }));
    }
  }
  handlerLastName(event) {
    event.preventDefault();
    const inputValue = event.target.value;
    if(this.state.errorLastName){
      this.setState(prevState => ({
        errorLastName: false
      }));
    }
    if (inputValue.length <= 20) {
      this.setState(prevState => ({
        shortInfoData: { ...prevState.shortInfoData, lastName: inputValue},
        errorLengthLastName: false
      }));
    } else {
      this.setState(prevState => ({
        errorLengthLastName: true
      }));
    }
  }
  handlerMiddleName(event) {
    event.preventDefault();
    const inputValue = event.target.value;
    if(this.state.errorMiddleName){
      this.setState(prevState => ({
        errorMiddleName: false
      }));
    }
    if (inputValue.length <= 20) {
      this.setState(prevState => ({
        shortInfoData: { ...prevState.shortInfoData, middleName:inputValue},
        errorLengthMiddleName: false
      }));
    } else {
      this.setState(prevState => ({
        errorLengthMiddleName: true
      }));
    }
  }
  validItemEmptyShortInfo = (data, item) => {
    let itemData = { ...this.state[data] };
    let itemValue = itemData[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let itemCorrect = itemValue.trim();
      return itemCorrect;
    }
    return itemValue;
  };
  handlerShortInfoSubmit (event) {
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      modalEditLoading: true,
      errorFields: false,
      errorDateBirth: false,
      errorName: false,
      errorMiddleName: false,
      errorLastName: false
    });
    let firstName = that.validItemEmptyShortInfo('shortInfoData', 'name');
    let middleName = that.validItemEmptyShortInfo('shortInfoData', 'middleName');
    let lastName = that.validItemEmptyShortInfo('shortInfoData', 'lastName');

    const nameRegex = /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ]+(?:[-—][a-zA-Zа-яА-ЯёЁіІїЇґҐ]+)*$/;
    if (!nameRegex.test(firstName) && firstName !== '') {
        error = true;
        this.setState({
          errorName: true
        });
    }
    if (!nameRegex.test(middleName) && middleName !== '') {
        error = true;
        this.setState({
          errorMiddleName: true
        });
    }
    if (!nameRegex.test(lastName) && lastName !== '') {
        error = true;
        this.setState({
          errorLastName: true
        });
    }


    let shortInfoData = that.state.shortInfoData;
    if(firstName === '' || lastName === ''){
      error = true;
    }
    shortInfoData.name = firstName;
    shortInfoData.lastName = lastName;
    shortInfoData.middleName = middleName;
    this.setState({
      shortInfoData: shortInfoData
    });

    if(that.state.birthdayDateValue !== '..'){
      let currentDate = new Date();
      let dateComponents = that.state.birthdayDateValue.split('.');
      let specifiedDate = new Date(dateComponents[2], dateComponents[1] - 1, dateComponents[0]);
      if(currentDate <= specifiedDate){
        error = true;
        this.setState({
          errorDateBirth: true
        });
      }
    }

    var datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
    if(!datePattern.test(that.state.birthdayDateValue)){
      error = true;
      this.setState({
        errorDateBirth: true
      });
    }

    if(!error){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/short-info`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify(shortInfoData)
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                modalEditLoading: false,
                errors: data.errors
              })
            });
          } else {
            localStorage.setItem("navName", that.state.shortInfoData.name);
            that.props.updateUserAccountNavInfo(that.state.shortInfoData.name);
            that.setState(prevState => ({
              modalEditLoading: false,
              userData: { ...prevState.userData, name: prevState.shortInfoData.name, lastName: prevState.shortInfoData.lastName, middleName: prevState.shortInfoData.middleName, dateOfBirth: prevState.birthdayDateValue },
              successSubmit: true
            }));
            setTimeout(() => {
              that.setState({
                successSubmit: false
              });
            }, 5000);
            that.handleModalDisabled();
            }
          })
      })
    } else {
      that.setState({
        modalEditLoading: false,
        errorFields: true
      });
    }
  }
  handlerModalResponsibilities () {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/responsibilities`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            data.roles.map((item, index) => {
              switch (item) {
                case 'ROLE_ADMINISTRATOR':
                  that.setState(prevState => ({
                    rolesUser: [...prevState.rolesUser, {value: item, label: that.props.getTranslate('role-administrator')} ]
                  }));
                  break;
                case 'ROLE_ACCOUNTANT_BO':
                  that.setState(prevState => ({
                    rolesUser: [...prevState.rolesUser, {value: item, label: 'Бухгалтер'} ]
                  }));
                  break;
                case 'ROLE_ADMIN_BO':
                  that.setState(prevState => ({
                    rolesUser: [...prevState.rolesUser, {value: item, label: 'Адміністратор бекофісу'} ]
                  }));
                  break;
                case 'ROLE_MANAGER_BO':
                  that.setState(prevState => ({
                    rolesUser: [...prevState.rolesUser, {value: item, label: 'Менеджер'} ]
                  }));
                  break;
                case 'ROLE_SUPPORT_BO':
                  that.setState(prevState => ({
                    rolesUser: [...prevState.rolesUser, {value: item, label: 'Підтримка'} ]
                  }));
                  break;
                case 'ROLE_DEVELOPER_BO':
                  that.setState(prevState => ({
                    rolesUser: [...prevState.rolesUser, {value: item, label: 'Розробник'} ]
                  }));
                  break;
                default:
                  that.setState(prevState => ({
                    rolesUser: [...prevState.rolesUser, {value: item, label: that.props.getTranslate('role-user')} ]
                  }));
              }
            });
            that.setState(prevState => ({
              responsibilitiesData: {
                position: data.position,
                roles: data.roles,
                userID: data.userID
              }
            }));
          });
          that.setState(prevState => ({
            modalResponsibilities: !prevState.modalResponsibilities
          }));
        }
      })
    })
  }
  handlerPassword(event){
    if(this.state.errorFields){
      this.setState(prevState => ({
        errorFields: false
      }));
    }
    this.setState(prevState => ({
      changePass: true,
      passwordAuth: event.target.value
    }));
  }
  handlerModalAuth(){
    this.setState(prevState => ({
      modalAuth: !prevState.modalAuth,
      emailAuth: prevState.userData.email
    }));
  }

  handlerModalPassword(){
    this.setState(prevState => ({
      modalPassword: !prevState.modalPassword
    }));
  }

  handlerAuthSubmit(event) {
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      modalEditLoading: true,
      errorFields: false,
      errorEmail: false,
      errorPassword: false,
      errorPasswordLength: false,
      errorCodeChangeEmail: null
    });
    const auth = getAuth();
    const currentUser = auth.currentUser;
    let password = that.state.passwordAuth.trim();
    if (currentUser) {
      let credential = EmailAuthProvider.credential(currentUser.email, password);
      reauthenticateWithCredential(currentUser, credential)
      .then(() => {

        let userDataAuth = {
          'email': that.state.emailAuth,
          'userID': that.state.userData.userID
        };
        if(that.state.emailAuth !== '' && !validateEmail(that.state.emailAuth)){
          this.setState({
            errorEmail: true
          });
          error = true;
        }
        if(that.state.emailAuth == ''){
          error = true;
        }
        if(!error){
          getAuth().currentUser.getIdToken().then(function(idToken){
            const myHeaders = new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + idToken
            });
            fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/contacts`, {
              method: 'PUT',
              headers: myHeaders,
              body: JSON.stringify(userDataAuth)
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  });
                });
              } else {
                res.json().then(function(data) {
                  let userData = that.state.userData;
                  userData.email = data.email;
                  that.setState(prevState => ({
                    userData: userData,
                    modalEditLoading: false
                  }));
                  setTimeout(() => {
                    that.setState({
                      successSubmit: false
                    });
                  }, 5000);
                  that.handleModalDisabled();
                })
              }
            })
          })
        } else {
          that.setState({
            errorFields: true,
            modalEditLoading: false
          });
        }

      }).catch((error) => {
                  this.setState({
                    errorCodeChangeEmail: error.code,
                    modalEditLoading: false
                  });
                });
    }
  }

handlerPasswordSubmit(event){
  event.preventDefault();
  let that = this;
  let error = false;
  let userDataAuth = {
    'userID': that.state.userData.userID
  };
  this.setState({
    modalEditLoading: true,
    errorFields: false,
    errorEmail: false,
    errorPassword: false,
    errorPasswordLength: false,
    successSubmit: false,
    errorCurrentPass: false,
    errorPasswordMatch: false
  });
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (currentUser) {
    let currentpassword = this.state.currentPassValue;
    let password = that.state.newPassValue.trim();
    let repassword = that.state.rePassValue.trim();
    let credential = EmailAuthProvider.credential(currentUser.email, currentpassword);
    reauthenticateWithCredential(currentUser, credential)
    .then(() => {
      if(password && password.length < 6){
        that.setState({
          errorPasswordLength: true
        });
        error = true;
      }
      if(password !== repassword){
        that.setState({
          errorPasswordMatch: true
        });
        error = true;
      }
      if(password && validatePassword(password)){
        that.setState({
          errorPassword: true
        });
        error = true;
      }
      if(!error){
        userDataAuth.email = currentUser.email;
        userDataAuth.password = password;
        getAuth().currentUser.getIdToken().then(function(idToken){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });
          fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/contacts`, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify(userDataAuth)
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              });
            } else {
              res.json().then(function(data) {
                that.setState(prevState => ({
                  modalEditLoading: false,
                  successSubmit: true
                }));
                setTimeout(() => {
                  that.setState({
                    successSubmit: false
                  });
                }, 5000);
                that.handleModalDisabled();
              })
            }
          })
        })
      } else {
        this.setState({
          modalEditLoading: false,
          errorFields: true
        });
      }

    }).catch((error) => {
      this.setState({
        modalEditLoading: false,
        errorCurrentPass: true
      });
    });
  }
}

  handlerPosition(event) {
    event.preventDefault();
    const inputValue = event.target.value;
    if(this.state.errorPosition){
      this.setState(prevState => ({
        errorPosition: false
      }));
    }
    if (inputValue.length <= 30) {
      this.setState(prevState => ({
        responsibilitiesData: { ...prevState.responsibilitiesData, position: inputValue },
        errorLengthPosition: false
      }));
    } else {
      this.setState(prevState => ({
        errorLengthPosition: true
      }));
    }
  }

  handlerRole(event){
    let roles = event.map(a => a.value);
    this.setState(prevState => ({
      rolesUser: event,
      responsibilitiesData: { ...prevState.responsibilitiesData, roles}
    }));
  }
  handlerResponsibilitiesSubmit (event) {
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      modalEditLoading: true,
      errorFields: false,
      errorPosition: false,
      errorRolesEmpty: false
    });
    let responsibilitiesData = that.state.responsibilitiesData;
    let position = that.validItemEmptyShortInfo('responsibilitiesData', 'position');
    const nameRegex = /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ]+(?:[-—][a-zA-Zа-яА-ЯёЁіІїЇґҐ]+)*$/;
    if (!nameRegex.test(position) && position !== '') {
        error = true;
        this.setState({
          errorPosition: true
        });
    }
    responsibilitiesData.position = position;
    this.setState({
      responsibilitiesData: responsibilitiesData
    });
    if(responsibilitiesData.roles < 1){
      error = true;
      this.setState({
        errorRolesEmpty: true
      });
    }
    if(!error){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/responsibilities`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify(responsibilitiesData)
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                modalEditLoading: false,
                errors: data.errors
              })
            });
          } else {
            console.log(that.state.responsibilitiesData);
            that.setState(prevState => ({
              modalEditLoading: false,
              userData: { ...prevState.userData, position: prevState.responsibilitiesData.position, roles: prevState.responsibilitiesData.roles },
              successSubmit: true
            }));
            setTimeout(() => {
              that.setState({
                successSubmit: false
              });
            }, 5000);
            that.handleModalDisabled();
          }
        })
      })
    } else {
      this.setState({
        modalEditLoading: false,
        errorFields: true
      });
    }
  }
  handlerModalContacts(){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/contacts`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState(prevState => ({
              contactsData: {
                email: data.email,
                phone: data.phone,
                userID: data.userID
              }
            }));
          })
          that.setState(prevState => ({
            modalContacts: !prevState.modalContacts
          }));
        }
      })
    });
  }
  handlerPhone(event){
    event.preventDefault();
    this.setState(prevState => ({
      contactsData: { ...prevState.contactsData, phone: event.target.value}
    }));
  }
  handlerEmail(event){
    event.preventDefault();
    this.setState(prevState => ({
      emailAuth: event.target.value
    }));
  }
  handlerContactsSubmit (event) {
    event.preventDefault();
    let that = this;
    let error = false;
    let contactsData = that.state.contactsData;
    this.setState({
      errorFormatNumber: false,
      modalEditLoading: true
    });

    const phoneRegex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    if (!phoneRegex.test(contactsData.phone)) {
      error = true;
      this.setState({
        errorFormatNumber: true,
        modalEditLoading: false
      });
      return false;
    }

    if(!error) {

    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
      fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/contacts`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(contactsData)
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              modalEditLoading: false,
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState(prevState => ({
              modalEditLoading: false,
              userData: { ...prevState.userData, email: prevState.contactsData.email },
              successSubmit: true
            }));
            that.handleModalDisabled();
            setTimeout(() => {
              that.setState({
                successSubmit: false
              });
            }, 5000);
          })
        }
      })
    })
  } else {
    that.setState({
      modalEditLoading: false
    })
  }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/me`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              error: true
            })
          });
        } else {
          res.json().then(function(data) {
            let objResult = data;
            let dateBirth = objResult.dateOfBirth ? objResult.dateOfBirth : null;
            that.setState({
              isLoaded: true,
              userData: objResult,
              birthdayDateValue: dateBirth
            });
          });
        }
      })
      fetch(`${that.props.queryLink}/api/v1/users/roles`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState(prevState => ({
              roles: data
            }));
          });
        }
      })
    })
  }
  handlerCloseSuccess (event) {
    event.preventDefault();
    this.setState(prevState => ({
      successSubmit: false
    }));
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerSetAvatarOpenModal(event){
    let files = event.target.files;
    if(files){
      this.setState({
        imageSrc: URL.createObjectURL(files[0]),
        imagePhotoName: files[0].name,
        formDataPhoto: files,
        modalPhoto: true
      });
    }
  }
  handlerSetAvatar(event){
    let that = this;
    this.setState(prevState => ({
      isLoadingPhoto: true
    }));

    if(that.state.cropImageStatus){
      let croppedImage = '';
      croppedImage = getCroppedImg(
        that.state.imageSrc,
        that.state.croppedAreaPixels,
        that.state.rotation
      );
      const myPromise = new Promise((resolve, reject) => {
        const result = croppedImage;
        resolve(result);
      });
      myPromise.then((result) => {
        var img = new Image();
        img.src = result;
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        img.onload = function() {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL('image/jpeg');
          var blob = dataURLToBlob(dataURL);
          const formData = new FormData();
          formData.append('file', blob, that.state.imagePhotoName);

          getAuth().currentUser.getIdToken().then(function(idToken){
            const myHeaders = new Headers({
              'Authorization': 'Bearer ' + idToken
            });
            fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/photos`, {
              method: 'POST',
              headers: myHeaders,
              body: formData
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors,
                    isLoadingPhoto: false
                  })
                });
              } else{
                res.json().then(function(data) {
                  localStorage.setItem("navPhoto", data.url);
                  that.setState(prevState => ({
                    userData: { ...prevState.userData, photo: data.url },
                    isLoadingPhoto: false
                  }))
                });
                that.handleModalDisabled();
              }
            })
          })
          function dataURLToBlob(dataURL) {
            var byteString = atob(dataURL.split(',')[1]);
            var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], {type: mimeString});
          }
        };
        });
      }
  }
  handlerRemovePhoto(){
    let that = this;
    this.setState(prevState => ({
      isLoadingPhoto: true
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
        'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${that.state.userData.userID}/photos`, {
        method: 'DELETE',
        headers: myHeaders,
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              isLoadingPhoto: false
            })
          });
        } else {
          let accountNavAvatar = document.querySelector('.account-nav-list__name img');
          accountNavAvatar.parentNode.removeChild(accountNavAvatar);
          let elem = document.querySelector('.account-nav-list__name svg');
          if(elem){
            elem.style.display = 'block';
          }
          localStorage.removeItem('navPhoto');
          that.setState(prevState => ({
            userData: { ...prevState.userData, photo: null },
            isLoadingPhoto: false
          }))
        }
      })
    })
  }
  handlerAvatarScale(status){
    this.setState({
      avatarScale: status
    });
  }
  handlerGetDate (event) {
    let date = this.state.userData.dateOfBirth;
    if(date){
      date = date.split('-');
      date = new Date(date[2], date[1], date[0]);
    } else {
      date = this.state.date;
    }
    event.preventDefault();
    this.handleModalDisabled();
    let fullYear = date.getFullYear();
    let month = date.getMonth() - 1;
    let current = date.getDate();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    setTimeout(() => {
      this.setState(prevState => ({
        dateModal: !prevState.dateModal,
        dates: arr,
        fullYear: fullYear,
        pushElemsTotal: pushElemsNum,
        birthdayDate: date,
        month: month,
        date: new Date(fullYear, month, current)
      }));
    }, 300);
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    return monthes[num];
  }
  handlerCloseGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      dateModal: false
    }));
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month
    }));
  }
  chooseDate (nextMonth, event) {
    event.preventDefault();
    this.setState(prevState => ({
      birthdayDate: new Date(prevState.fullYear, prevState.month, event.target.innerHTML),
      startDate: prevState.fullYear + '-' + prevState.month + '-' + event.target.innerHTML,
      finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '-' + ((prevState.month + 1) < 10 ? '0' + (prevState.month + 1) : prevState.month + 1) + '-' + prevState.fullYear
    }));
  }
  chooseEndHover (event) {
    event.preventDefault();
    this.setState(prevState => ({
      hoverDate: null
    }));
  }
  onKeyPress (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode === 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += '.';
    }
    if(len === 5) {
      input.value += '.';
    }
  }
  onKeyPress2 (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode === 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += ':';
    }
  }
  setDateInput (event) {
    event.preventDefault();
    this.setState(prevState => ({
      chooseDateFull: prevState.finishStartDate,
      dateModal: !prevState.dateModal,
      modalInfo: !prevState.modalInfo,
      shortInfoData: {...prevState.shortInfoData, dateOfBirth: prevState.finishStartDate}
    }));
  }
  handlerBirthdayDate(event){
    if(this.state.errorDateBirth){
      this.setState(prevState => ({
        errorDateBirth: false
      }));
    }
    let birthdayDateValueCount = this.state.birthdayDateValueCount + 1;
    this.setState(prevState => ({
      birthdayDateValue: event,
      shortInfoData: {...prevState.shortInfoData, dateOfBirth: event.split('-').reverse().join('.')},
      birthdayDateValueCount: birthdayDateValueCount
    }));
  }
  handlerBirthdayDateError(event){
    let birthdayDateValueCount = this.state.birthdayDateValueCount;
    let birthdayDateValueCountPress = this.state.birthdayDateValueCountPress;
    if(event.code === 'Backspace'){
      birthdayDateValueCountPress = 0;
      birthdayDateValueCount = 0;
      this.setState(prevState => ({
        errorDateBirth: false
      }));
    } else {
      birthdayDateValueCountPress = this.state.birthdayDateValueCountPress + 1;
    }
    this.setState(prevState => ({
      birthdayDateValueCountPress: birthdayDateValueCountPress,
      birthdayDateValueCount: birthdayDateValueCount
    }));
    if(birthdayDateValueCount < birthdayDateValueCountPress){
      this.setState(prevState => ({
        errorDateBirth: true
      }));
    } else {
      this.setState(prevState => ({
        errorDateBirth: false
      }));
    }
  }

  handlerChangePass(field, event){
    this.setState({ [field]: event.target.value });
  }
  handlerPasswordView(field, event){
    this.setState(prevState => ({
      [field]: !prevState[field]
    }));
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('userinfo-AccountTitle') + ' | ' + this.props.companyName;
    }
    let roles = [];
    this.state.roles.map((item, index) => {
      let label = '';
      if(item === 'ROLE_ADMINISTRATOR'){
        label = this.props.getTranslate('role-administrator');
      } else if(item === 'ROLE_ACCOUNTANT_BO') {
        label = 'Бухгалтер';
      } else if(item === 'ROLE_ADMIN_BO') {
        label = 'Адміністратор бекофісу';
      } else if(item === 'ROLE_SUPPORT_BO') {
        label = 'Підтримка';
      } else if(item === 'ROLE_DEVELOPER_BO') {
        label = 'Розробник';
      } else if(item === 'ROLE_MANAGER_BO') {
        label = 'Менеджер';
      } else {
        label = this.props.getTranslate('role-user');
      }
      roles = [...roles, {value: item, label: label} ]
    });
    let accountInfo = '';
    if (this.state.error) {
      accountInfo = <div className="errors-content">Ошибка...</div>;
    } else if (!this.state.isLoaded) {
      accountInfo = <Loading />;
    } else {
      let qw = window.location.protocol;
      let photo = this.state.userData.photo;
      if(photo){
        if(qw === 'http:'){
          photo = photo.replace( /https:/g, "http:" );
        }
      }
      accountInfo = <>
        <div className="account-wgt account-content__wgt">
          <h2 className="account-wgt__header">{this.props.getTranslate('userinfo-DataTitle')}</h2>
          <div className="account-wgt__inner">
            {this.state.userData.name && <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-Name')}</div>
              <div className="account-item__value">{this.state.userData.name}</div>
            </div>}
            {this.state.userData.lastName && <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-LastName')}</div>
              <div className="account-item__value">{this.state.userData.lastName}</div>
            </div>}
            {this.state.userData.middleName && <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-MidlName')}</div>
              <div className="account-item__value">{this.state.userData.middleName}</div>
            </div>}
            {this.state.userData.dateOfBirth && <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-Date')}</div>
              <div className="account-item__value">{`${this.state.userData.dateOfBirth} (${getAge(this.state.userData.dateOfBirth)} ${getAgeSuffix(getAge(this.state.userData.dateOfBirth), this.props.getTranslate)})`}</div>
            </div>}
             <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-Photo')}</div>
             {this.state.userData.photo ? <div className="account-item__avatar account-item__avatar_exists">
              <img className="account-item__avatar-img" src={this.state.userData.photo} alt="" onMouseEnter={this.handlerAvatarScale.bind(this, true)} onMouseLeave={this.handlerAvatarScale.bind(this, false)} />
              {this.state.avatarScale && <img className="account-item__avatar-img-scale" src={this.state.userData.photo} alt="" />}
              <label className="account-item__avatar-label" htmlFor="set-avatar">{this.props.getTranslate('userinfo-PhotoChange')}</label>
              <input id="set-avatar" className="account-item__avatar-input" type="file" onChange={this.handlerSetAvatarOpenModal.bind(this)} />
              <button className="account-item__avatar-remove" onClick={this.handlerRemovePhoto.bind(this)}>{this.props.getTranslate('userinfo-PhotoRemove')}{this.state.isLoadingPhoto && <Loading />}</button>
             </div> : <div className="account-item__avatar">
              <img className="account-item__avatar-img" src={user_img} alt="" />
              <label className="account-item__avatar-label" htmlFor="set-avatar">{this.props.getTranslate('userinfo-PhotoAdd')}</label>
              <input id="set-avatar" className="account-item__avatar-input" type="file" onChange={this.handlerSetAvatarOpenModal.bind(this)} />
             </div>}
            </div>
          </div>
          <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalInfo.bind(this)}>{this.props.getTranslate('userinfo-EditData')}</button>
        </div>
        <div className="account-wgt account-content__wgt account-content__wgt_p_t">
          <h2 className="account-wgt__header">{this.props.getTranslate('userinfo-PositionTitle')}</h2>
          <div className="account-wgt__inner">
            {this.state.userData.position && <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-Position')}</div>
              <div className="account-item__value">{this.state.userData.position}</div>
            </div>}
            {this.state.userData.roles && <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-Access')}</div>
              <div className="account-item__value">
              {this.state.userData.roles.map((role, roleIndex) => {
                let roleName = '';
                switch (role) {
                  case 'ROLE_SUPER_ADMIN':
                    roleName = 'Супер адміністратор';
                    break;
                  case 'ROLE_ADMINISTRATOR':
                    roleName = this.props.getTranslate('role-administrator');
                    break;
                  case 'ROLE_ACCOUNTANT_BO':
                    roleName = 'Бухгалтер';
                    break;
                  case 'ROLE_ADMIN_BO':
                    roleName = 'Адміністратор бекофісу';
                    break;
                  case 'ROLE_DEVELOPER_BO':
                    roleName = 'Розробник';
                    break;
                  case 'ROLE_SUPPORT_BO':
                    roleName = 'Підтримка';
                    break;
                  case 'ROLE_MANAGER_BO':
                    roleName = 'Менеджер';
                    break;
                  case 'ROLE_EMPLOYEE':
                    roleName = this.props.getTranslate('role-user');
                    break;
                  default:
                    roleName = role;
                }
                return <span key={roleIndex}>{roleName}{this.state.userData.roles.length === (roleIndex + 1) ? '' : ', '}</span>
              })}
              </div>
            </div>}
          </div>
          <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalResponsibilities.bind(this)}>{this.props.getTranslate('userinfo-EditData')}</button>
        </div>
        <div className="account-wgt account-content__wgt account-content__wgt_p_t">
          <h2 className="account-wgt__header">{this.props.getTranslate('userinfo-ContactsTitle')}</h2>
          <div className="account-wgt__inner">
            {this.state.userData.phone && <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-Phone')}</div>
              <div className="account-item__value">{this.state.userData.phone}</div>
            </div>}
            {this.state.userData.email && <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-Email')}</div>
              <div className="account-item__value">{this.state.userData.email}</div>
            </div>}
          </div>
          <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalContacts.bind(this)}>{this.props.getTranslate('userinfo-EditData')}</button>
        </div>
        <div className="account-wgt account-content__wgt account-content__wgt_p_t">
          <h2 className="account-wgt__header">{this.props.getTranslate('userinfo-LoginPassTitle')}</h2>
          <div className="account-wgt__inner">
            {this.state.userData.email && <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-LoginMail')}</div>
              <div className="account-item__value account-item__value-name">{this.state.userData.email}</div>
              <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalAuth.bind(this)}>{this.props.getTranslate('userinfo-EditData')}</button>
            </div>}
            <div className="account-item account-wgt__item">
              <div className="account-item__headline">{this.props.getTranslate('userinfo-Pass')}</div>
              <div className="account-item__value account-item__value-name">************</div>
              <button className="account-wgt__edit-btn" type="button" onClick={this.handlerModalPassword.bind(this)}>{this.props.getTranslate('userinfo-EditData')}</button>
            </div>
          </div>
        </div>
      </>;
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {this.state.successSubmit && <div className="response-submit">
    <div className="response-submit__item response-submit__item_success">{this.props.getTranslate('successStatus-Save')}
      <button className="response-submit__close" type="button" onClick={this.handlerCloseSuccess.bind(this)}>
      <svg><use href={`${sprite}#close-icon`}></use></svg>
      </button></div></div>}
      {this.state.errorSubmit && <div className="response-submit">
      <div className="response-submit__item response-submit__item_error">Ошибка!
        <button className="response-submit__close" type="button" onClick={this.handlerCloseError.bind(this)}>
        <svg><use href={`${sprite}#close-icon`}></use></svg>
        </button></div></div>}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed}  />
    <div className="account-wrap">
      <AccountNav newName={this.props.newName} getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              title={this.props.getTranslate('userinfo-AccountTitle')}
              addClass="account-content__header_hide"
            />

            {accountInfo}
          </div>
      </div>
    </div>
    {this.state.modalInfo ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
  <div className="modal-background" onClick={this.handleModalDisabled.bind(this)}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('userinfo-DataTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <form onSubmit={this.handlerShortInfoSubmit.bind(this)}>
      <div className="field-wrap">
        <label className="field-wrap__label" htmlFor="name">{this.props.getTranslate('userinfo-Name')}</label>
        <input id="name" className={`field-wrap__input ${(this.state.errorFields && this.state.shortInfoData.name === '') || this.state.errorLengthName || this.state.errorName ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.shortInfoData.name ? this.state.shortInfoData.name : ''} onChange={this.handlerName.bind(this)} placeholder={this.props.getTranslate('userinfo-NameFieldCaption')} />
        {this.state.errorFields && this.state.shortInfoData.name === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
        {this.state.errorLengthName ? <div className="validation-error">{this.props.getTranslate('validation-UserNameLength')}</div> : ''}
        {this.state.errorName ? <div className="validation-error">{this.props.getTranslate('validation-UserNameRegisterValidation')}</div> : ''}
      </div>
      <div className="field-wrap">
        <label className="field-wrap__label" htmlFor="secondname">{this.props.getTranslate('userinfo-LastName')}</label>
        <input id="secondname" className={`field-wrap__input ${(this.state.errorFields && this.state.shortInfoData.lastName === '') || this.state.errorLengthLastName || this.state.errorlastName ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.shortInfoData.lastName ? this.state.shortInfoData.lastName : ''} onChange={this.handlerLastName.bind(this)} placeholder={this.props.getTranslate('userinfo-LastNameFieldCaption')} />
        {this.state.errorFields && this.state.shortInfoData.lastName === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
        {this.state.errorLengthLastName ? <div className="validation-error">{this.props.getTranslate('validation-UserLastNameLength')}</div> : ''}
        {this.state.errorlastName ? <div className="validation-error">{this.props.getTranslate('validation-UserLastNameRegisterValidation')}</div> : ''}
      </div>
      <div className="field-wrap">
        <label className="field-wrap__label" htmlFor="surname">{this.props.getTranslate('userinfo-MidlName')}</label>
        <input id="surname" className={`field-wrap__input ${this.state.errorLengthMiddleName || this.state.errorMiddleName ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.shortInfoData.middleName ? this.state.shortInfoData.middleName : ''} onChange={this.handlerMiddleName.bind(this)} placeholder={this.props.getTranslate('userinfo-MidlNameFieldCaption')} />
        {this.state.errorLengthMiddleName ? <div className="validation-error">{this.props.getTranslate('validation-UserMiddleNameLength')}</div> : ''}
        {this.state.errorMiddleName ? <div className="validation-error">{this.props.getTranslate('validation-UserMiddleNameRegisterValidation')}</div> : ''}
      </div>
      <div className="field-wrap questions-form__date">
        <label className="field-wrap__label" htmlFor="birthday">{this.props.getTranslate('userinfo-Date')}</label>
        <div className="questions-form__date-inner">
          <svg className="questions-form__date-icon">
            <use href={`${sprite}#date-icon`}></use>
          </svg>
                      <IMaskInput
                        className={`field-wrap__input questions-form__date-field ${this.state.errorDateBirth ? 'field-wrap__input_error' : ''}`}
                        mask={Date}
                        min={new Date(1900, 0, 1)}
                        max={new Date()}
                        lazy={false}
                        unmask={true}
                        value={this.state.birthdayDateValue}
                        onAccept={this.handlerBirthdayDate.bind(this)}
                        onKeyUp={this.handlerBirthdayDateError.bind(this)}
                      />
        </div>
        {this.state.errorDateBirth ? <div className="validation-error">{this.props.getTranslate('validation-errorDateBirth')}</div> : ''}
      </div>
        <div className="register-form__submit">
            <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" disabled={this.state.modalEditLoading? true : false}>{this.props.getTranslate('userinfo-SaveButton')}{this.state.modalEditLoading && <Loading />}</button>
        </div>
      </form>
  </div>
</div> : '' }
    {this.state.modalResponsibilities ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
          <div className="modal-background" onClick={this.handleModalDisabled}></div>
            <div className="modal">
              <div className="modal-header">
                <h2 className="modal-headline">{this.props.getTranslate('userinfo-PositionTitle')}</h2>
                <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                  <svg className="close-modal__icon">
                    <use href={`${sprite}#close-icon`}></use>
                  </svg>
                </button>
              </div>
              <form onSubmit={this.handlerResponsibilitiesSubmit.bind(this)}>
                <div className="field-wrap">
                  <label className="field-wrap__label" htmlFor="position">{this.props.getTranslate('userinfo-Position')}</label>
                  <input id="position" className={`field-wrap__input ${this.state.errorLengthPosition || this.state.errorPosition ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.responsibilitiesData.position ? this.state.responsibilitiesData.position : ''} onChange={this.handlerPosition.bind(this)} placeholder={this.props.getTranslate('userinfo-PositionFieldCaption')} />
                  {this.state.errorLengthPosition ? <div className="validation-error">{this.props.getTranslate('validation-PositionLength')}</div> : ''}
                  {this.state.errorPosition ? <div className="validation-error">{this.props.getTranslate('validation-errorFormatPosition')}</div> : ''}
                </div>
                <div className="field-wrap">
                  <label className="field-wrap__label">{this.props.getTranslate('userinfo-Access')}</label>
                  {this.state.rolesUser && <Select
                    className="field-wrap__select"
                    styles={this.state.errorRolesEmpty ? customStylesError : customStyles}
                    options={roles}
                    isMulti={true}
                    value={this.state.rolesUser}
                    placeholder={this.props.getTranslate('userinfo-Access')}
                    onChange={this.handlerRole.bind(this)}
                  />}
                  {this.state.errorRolesEmpty ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                </div>
                <div className="register-form__submit">
                    <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" disabled={this.state.modalEditLoading? true : false}>{this.props.getTranslate('userinfo-SaveButton')}{this.state.modalEditLoading && <Loading />}</button>
                </div>
              </form>
              </div>
            </div> : ''}
            {this.state.modalContacts ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
                  <div className="modal-background" onClick={this.handleModalDisabled}></div>
                    <div className="modal">
                      <div className="modal-header">
                        <h2 className="modal-headline">{this.props.getTranslate('userinfo-ContactsTitle')}</h2>
                        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                          <svg className="close-modal__icon">
                            <use href={`${sprite}#close-icon`}></use>
                          </svg>
                        </button>
                      </div>
                      <form onSubmit={this.handlerContactsSubmit.bind(this)}>
                        <div className="field-wrap">
                          <label className="field-wrap__label" htmlFor="phone">{this.props.getTranslate('userinfo-Phone')}</label>
                          <input id="phone" className={`field-wrap__input ${this.state.errorFormatNumber ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.contactsData.phone ? this.state.contactsData.phone : ''} onChange={this.handlerPhone.bind(this)} placeholder={this.props.getTranslate('userinfo-PhoneFieldCaption')} />
                          {this.state.errorFormatNumber ? <div className="validation-error">{this.props.getTranslate('validation-errorFormatNumber')}</div> : ''}
                        </div>
                        <div className="field-wrap">
                          <label className="field-wrap__label" htmlFor="email">{this.props.getTranslate('userinfo-Email')}</label>
                          <input id="email" className="field-wrap__input" type="email" value={this.state.contactsData.email ? this.state.contactsData.email : ''} onChange={this.handlerEmail.bind(this)} placeholder={this.props.getTranslate('userinfo-EmailFieldCaption')} />
                        </div>
                        <div className="register-form__submit">
                            <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" disabled={this.state.modalEditLoading? true : false}>{this.props.getTranslate('userinfo-SaveButton')}{this.state.modalEditLoading && <Loading />}</button>
                        </div>
                      </form>
                      </div>
                    </div> : ''}
                    {this.state.modalAuth ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
                          <div className="modal-background" onClick={this.handleModalDisabled}></div>
                            <div className="modal">
                              <div className="modal-header">
                                <h2 className="modal-headline">{this.props.getTranslate('userinfo-LoginPassTitle')}</h2>
                                <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                                  <svg className="close-modal__icon">
                                    <use href={`${sprite}#close-icon`}></use>
                                  </svg>
                                </button>
                              </div>
                              <form onSubmit={this.handlerAuthSubmit.bind(this)}>
                                <div className="field-wrap">
                                  <label className="field-wrap__label" htmlFor="email">{this.props.getTranslate('userinfo-Email')}</label>
                                  <input id="email" className={`field-wrap__input ${(this.state.errorFields && (this.state.emailAuth === '' || this.state.errorEmail)) ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.emailAuth} onChange={this.handlerEmail.bind(this)} placeholder={this.props.getTranslate('userinfo-EmailFieldCaption')} />
                                  {this.state.errorFields && this.state.emailAuth === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                                  {this.state.errorFields && this.state.errorEmail ? <div className="validation-error">{this.props.getTranslate('validation-IncorrectEmail')}</div> : ''}
                                </div>
                                <div className="field-wrap">
                                  <label className="field-wrap__label" htmlFor="password">{this.props.getTranslate('userinfo-Pass')}</label>
                                  <input id="password" className={`field-wrap__input ${(this.state.errorFields && this.state.changePass && (!this.state.passwordAuth || this.state.errorPasswordLength || this.state.errorPassword)) || this.state.errorCodeChangeEmail === 'auth/missing-password' || this.state.errorCodeChangeEmail === 'auth/wrong-password' ? 'field-wrap__input_error' : ''}`} type="password" value={this.state.passwordAuth} onChange={this.handlerPassword.bind(this)} placeholder="Введіть пароль" />
                                  {this.state.errorFields && this.state.changePass && this.state.passwordAuth === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                                  {this.state.errorFields && this.state.changePass && this.state.errorPasswordLength ? <div className="validation-error">{this.props.getTranslate('validation-LengthPass')}</div> : ''}
                                  {this.state.errorFields && this.state.changePass && this.state.errorPassword ? <div className="validation-error">{this.props.getTranslate('validation-LatinPass')}</div> : ''}
                                  {this.state.errorCodeChangeEmail === 'auth/missing-password' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                                  {this.state.errorCodeChangeEmail === 'auth/wrong-password' ? <div className="validation-error">Невірний пароль</div> : ''}
                                </div>
                                <div className="register-form__submit">
                                    <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" disabled={this.state.modalEditLoading? true : false}>{this.props.getTranslate('userinfo-SaveButton')}{this.state.modalEditLoading && <Loading />}</button>
                                </div>
                              </form>
                              </div>
                            </div> : ''}

                            {this.state.modalPassword ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
                                  <div className="modal-background" onClick={this.handleModalDisabled}></div>
                                    <div className="modal">
                                      <div className="modal-header">
                                        <h2 className="modal-headline">Змінити пароль</h2>
                                        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                                          <svg className="close-modal__icon">
                                            <use href={`${sprite}#close-icon`}></use>
                                          </svg>
                                        </button>
                                      </div>
                                      <form onSubmit={this.handlerPasswordSubmit.bind(this)}>
                                        <div className="field-wrap field-wrap__sep">
                                        <label className="field-wrap__label" htmlFor="current-pass">Введіть поточний пароль</label>
                                            <div className="field-wrap__inner">
                                            <input id="current-pass" className={`field-wrap__input ${this.state.errorCurrentPass ? 'field-wrap__input_error' : ''}`} type={this.state.passwordHideCurrent ? 'password' : 'text'} value={this.state.currentPassValue} onChange={this.handlerChangePass.bind(this, 'currentPassValue')} placeholder="Введіть поточний пароль" />
                                            {this.state.errorCurrentPass ? <div className="validation-error">Не вірний поточний пароль</div> : ''}
                                            <button className="field-wrap__hide-btn" type="button" onClick={this.handlerPasswordView.bind(this, 'passwordHideCurrent')}>
                                              <svg className="field-wrap__hide-icon">
                                                <use href={this.state.passwordHideCurrent ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                        <div className="field-wrap">
                                        <label className="field-wrap__label" htmlFor="new-pass">Новий пароль</label>
                                          <div className="field-wrap__inner">
                                            <input id="new-pass" className={`field-wrap__input ${(this.state.errorFields && (this.state.errorPasswordLength || this.state.errorPassword)) ? 'field-wrap__input_error' : ''}`} type={this.state.passwordHideNew ? 'password' : 'text'} value={this.state.newPassValue} onChange={this.handlerChangePass.bind(this, 'newPassValue')} placeholder="Введіть новий пароль" />
                                            <button className="field-wrap__hide-btn" type="button" onClick={this.handlerPasswordView.bind(this, 'passwordHideNew')}>
                                              <svg className="field-wrap__hide-icon">
                                                <use href={this.state.passwordHideNew ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                        <div className="field-wrap">
                                        <label className="field-wrap__label" htmlFor="re-pass">Повторіть новий пароль</label>
                                          <div className="field-wrap__inner">
                                            <input id="re-pass" className={`field-wrap__input ${(this.state.errorFields && (this.state.errorPasswordLength || this.state.errorPassword)) ? 'field-wrap__input_error' : ''}`} type={this.state.passwordHideRe ? 'password' : 'text'} value={this.state.rePassValue} onChange={this.handlerChangePass.bind(this, 'rePassValue')} placeholder="Введіть новий пароль" />
                                            <button className="field-wrap__hide-btn" type="button" onClick={this.handlerPasswordView.bind(this, 'passwordHideRe')}>
                                              <svg className="field-wrap__hide-icon">
                                                <use href={this.state.passwordHideRe ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                                              </svg>
                                            </button>
                                            {this.state.errorFields && this.state.errorPasswordLength ? <div className="validation-error">{this.props.getTranslate('validation-LengthPass')}</div> : ''}
                                            {this.state.errorFields && this.state.errorPassword ? <div className="validation-error">{this.props.getTranslate('validation-LatinPass')}</div> : ''}
                                          </div>
                                        </div>
                                        <div className="register-form__submit">
                                          <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" disabled={this.state.modalEditLoading? true : false}>{this.props.getTranslate('userinfo-SaveButton')}{this.state.modalEditLoading && <Loading />}</button>
                                        </div>
                                      </form>
                                      </div>
                                    </div> : ''}

                            {this.state.modalPhoto ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
                                  <div className="modal-background" onClick={this.handleModalDisabled}></div>
                                    <div className="modal">
                                      <div className="modal-header">
                                        <h2 className="modal-headline">{this.props.getTranslate('userinfo-ContactsTitle')}</h2>
                                        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                                          <svg className="close-modal__icon">
                                            <use href={`${sprite}#close-icon`}></use>
                                          </svg>
                                        </button>
                                      </div>
                                      <div className="modal-image-edit">
                                        {this.state.formDataPhoto ? <div className="modal-image-edit__main-preview modal-image-edit__main-avatar"><Cropper
                                          image={this.state.imageSrc}
                                          crop={this.state.crop}
                                          zoom={this.state.zoom}
                                          aspect={this.state.aspect}
                                          onCropChange={this.onCropChange}
                                          onCropComplete={this.onCropComplete}
                                          onZoomChange={this.onZoomChange}
                                          cropSize={{width: 320, height: 320}}
                                          showGrid={false}
                                        /></div> : ''}</div>
                                        <div className="register-form__submit field-wrap__submit">
                                            <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" onClick={this.handlerSetAvatar.bind(this)}>Зберегти{this.state.isLoadingPhoto && <Loading />}</button>
                                        </div>

                                      </div>
                                    </div> : ''}
    </>;
  }
}
export default User;
