import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import DateFormating from './components/DateFormating';
import Loading from './components/Loading';
import { Link, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import CalendarDropdownInterval from './components/CalendarDropdownInterval';
import sprite from './media/icons.svg';
import img_preview from './media/item-preview-reminder.png';
import member_1 from './media/avatar-1.png';
import { getAuth } from "firebase/auth";
let Editor;
let EditorState;
let stateToHTML;
let ContentState;
let convertFromHTML;
let convertToRaw;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  convertToRaw = require('draft-js').convertToRaw;
  stateToHTML = require('draft-js-export-html').stateToHTML;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class ReminderCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      previewStatus: false,
      headlineValue: '',
      contentState: '',
      modalDisabled: false,
      modal: false,
      getAllUsers: false,
      allUsers: false,
      getUsersIds: [],
      usersIds: [],
      chooseAllMembers: false,
      chooseMembersIds: [],
      reminderType: "NONE",
      startTime: null,
      endTime: null,
      startTimeCalendar: null,
      errorFields: false,
      errorNameOnlySpace: false,
      errorDescriptionOnlySpace: false,
      errorTime: false,
      errorDescriptionEmpty: false,
      errorLengthDescription: false,
      tempUsersIds: [],
      tempAllUsers: false,
      displayUsers: [],
      displayAllUsers: false,
      getUsersIdsHover: []
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      const div = document.querySelector( '.field-wrap-smile');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if ( ! withinBoundaries ) {
          this.setState( {
            getSmileModal: false
          } );
        }
      });
    }
  }
  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }
  handlerNextPreview(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.body.classList.add('preview');
    }
    let that = this;
    let error = false;
    let start = new Date(this.state.startTime + ' ' + '00:00:00');
    let end = new Date(this.state.endTime + ' ' + '00:00:00');
    let differenceInMilliseconds = end - start;
    let differenceInMinutes = differenceInMilliseconds / 1000 / 60;
    this.setState(prevState => ({
      errorFields: false,
      errorNameOnlySpace: false,
      errorTime: false,
      errorDescriptionEmpty: false,
      errorDiffDate: false,
      errorDiffMinutesDate: false
    }));
    if (start > end && that.state.reminderType !== 'NONE') {
      error = true;
      this.setState({
        errorDiffDate: true
      });
    }
    let nameValue = this.validItemEmpty('inputHeadline');
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    let contentState = this.state.contentState;
    let isOnlySpaces;
    let isEmpty;
    if (contentState && typeof contentState.getCurrentContent === 'function') {
      let s = contentState.getCurrentContent();
      let blocks = s.getBlockMap().toArray();
      isOnlySpaces = blocks.every(block => block.getText().trim() === '');
      isEmpty = s.hasText() === false;

      if (isEmpty) {
        this.setState({
          errorDescriptionEmpty: true,
          errorDescriptionOnlySpace: false
        });
      } else if (isOnlySpaces) {
        this.setState({
          errorDescriptionEmpty: false,
          errorDescriptionOnlySpace: true
        });
      } else {
        this.setState({
          errorDescriptionEmpty: false,
          errorDescriptionOnlySpace: false
        });
      }

      this.setState({
        contentState: contentState
      });
    } else {
      isEmpty = true;
      this.setState({
        errorDescriptionEmpty: true
      });
    }
    if(!this.state.startTimeCalendar){
      error = true;
      this.setState({errorTime: true });
    }
    if(this.state.usersIds.length < 1 && !this.state.getAllUsers){
      error = true;
    }
    if(!error && nameValue && !isOnlySpaces && !isEmpty){
      this.setState(prevState => ({
        previewStatus: !prevState.previewStatus
      }));
      let obj = {
            "allUsers": that.state.allUsers,
            "description": stateToHTML(this.state.contentState.getCurrentContent()),
            "employees": this.state.usersIds,
            "name": nameValue,
            "reminderType": this.state.reminderType,
          }
          if(that.state.startTime){
            obj.startTime = new Date(that.state.startTime + ' ' + that.state.startTimeCalendar + ':00').toISOString();
          } else {
          let start = that.state.startTimeCalendar;
          let date = new Date();
          let [hours, minutes] = start.split(':').map(Number);

          date.setHours(hours, minutes, 0, 0);
          obj.startTime = date.toISOString();
          }
          if(that.state.endTime){
            obj.endTime = new Date(that.state.endTime + ' 23:59:59').toISOString();
          } else {
            const date = new Date();
            date.setHours(23, 59, 59, 999);
            obj.endTime = date.toISOString();
          }
          this.setState({
            objForSubmit: obj
          });
      } else {
        this.setState({
          errorFields: true
        });
      }
  }

  handlerSubmitReminder (event) {
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedSubmit: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      let location;
let language = 'uk-UA';
if(typeof window !== "undefined" && typeof document !== 'undefined') {
location = document.location.pathname;
const isEnglish = location.startsWith('/en');
if (isEnglish) {
language = 'en-US'
}
}
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
          'Accept-Language': language
      });
      fetch(`${that.props.queryLink}/api/v1/reminders`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(that.state.objForSubmit)
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.message
            })
          });
        } else {
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            localStorage.setItem("successReminderSubmit", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Created')}))
          }
          that.setState({
            isLoadedSubmit: false,
            successSubmit: true
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
        }
      })
    })
  }

  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalData: {}
      }));
    }, 500);
  }

  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  handlerInputHeadline (event) {
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    if(event.target.value.length <= 100){
      this.setState({
        inputHeadline: event.target.value,
        inputHeadlineLength: false
      });
    } else {
      this.setState({
        inputHeadlineLength: true
      });
    }
  }

  handleEditorBlur = () => {
    const text = this.state.contentState.getCurrentContent().getPlainText();
    if (this.state.errorLengthDescription) {
      this.setState({
        errorLengthDescription: true
      });
    }
  };

  handleBeforeInput = (chars, editorState) => {
    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText();

    if (text.length >= 500) {
      this.setState({
        errorLengthDescription: true
      });
      return 'handled';
    }
    return 'not-handled';
  };
  onEditorStateChange = (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const text = currentContent.getPlainText();
    const rowLength = currentContent.getBlockMap().toArray();

    // Обновление высоты, если длина текста превышает лимит
    if (rowLength.length > 14) {
      const infoDiv = document.querySelector('.editor-class');
      const height = infoDiv.clientHeight;
      this.setState({
        heightInfo: height,
      });
    } else {
      this.setState({
        heightInfo: null,
      });
    }

    // Условие для ошибки длины
    if (text.length >= 500) {
      this.setState({
        errorLengthDescription: true,
      });
    } else {
      this.setState({
        errorLengthDescription: false,
      });
    }

    // Условие для проверки только пробелов
    if (this.state.errorDescriptionOnlySpace) {
      const blocks = currentContent.getBlockMap().toArray();
      const isOnlySpaces = blocks.every((block) => block.getText().trim() === '');
      if (!isOnlySpaces) {
        this.setState({
          errorDescriptionOnlySpace: false,
        });
      }
    }

    this.setState({
      contentState: editorState,
    });
  };


    handlePastedText = (text, html, editorState) => {
      const contentState = editorState.getCurrentContent();
      const pastedText = ContentState.createFromText(text);

      const totalLength = contentState.getPlainText().length + pastedText.getPlainText().length;

      if (totalLength > 500) {
        const truncatedText = pastedText.getPlainText();
        const newContentState = ContentState.createFromText(truncatedText);

        const newEditorState = EditorState.push(editorState, newContentState);

        this.onEditorStateChange(newEditorState);
        this.setState({
          errorLengthDescription: true
        });
        return 'handled';
      }

      const newEditorState = EditorState.push(editorState, pastedText);

      this.onEditorStateChange(newEditorState);
      return 'not-handled';
    };


  handlerReminderType (event) {
    this.setState({
      reminderType: event.value
    });
  }

  handlerAddMembers (event) {
    event.preventDefault();
    let that = this;
    if(event.target.tagName !== 'SPAN'){
      this.setState({
        modal: true,
        modalAddMembers: true,
        users: [],
        isLoadedUser: false,
        errorUsers: null
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                isLoadedUser: true,
                errorUsers: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedUser: true,
                users: data
              })
            });
          }
        })
      })
    }
  }
  handlerSearchUsers(event) {
    let arr = this.state.users;
    let newArr = [];
    this.setState({
      searchValue: event.target.value,
      usersSearch: []
    });
    let that = this;
    arr.map(function(item) {
      item.users.map(function(users) {
        if (users.name.includes(event.target.value)) {
           newArr.push(users);
          that.setState(prevState => ({
            usersSearch: [...prevState.usersSearch, users]
          }));
        }
      })
    });
  }
  handlerSetUsers(){
      let ids = [];
      if(this.state.getUsersIds){
        ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
      }
      this.setState(prevState => ({
        allUsers: this.state.getAllUsers ? true : false,
        usersIds: ids
      }));
    this.handleModalDisabled();
  }
  handlerGetAllUsers(){
    this.setState(prevState => ({
      tempAllUsers: !prevState.tempAllUsers,
      tempUsersIds: []
    }));
  }
  handlerToggleUser(index, name, id, event){
    if(this.state.tempAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.tempUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if (arr.some(item => item.id === id)) {
      arr = arr.filter(item => item.id !== id);
    } else {
      arr = [...arr, { id, name }];
    }
    this.setState(prevState => ({
      tempUsersIds: arr
    }));
  }
  handlerRemoveAllUsers(){
    let data = this.state.resultData;
    if(data){
      data.allUsers = false;
      data.users = [];
    }
    this.setState(prevState => ({
      tempAllUsers: false,
      tempUsersIds: false
    }));
  }
  handlerRemoveUser(id){
    let arr = this.state.tempUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))]
    }
    this.setState(prevState => ({
      displayUsers: arr,
      usersIDs: ids
    }));
  }
  handlerSetUsers(){
      let ids = [];
      if(this.state.tempUsersIds){
        ids = [...new Set(this.state.tempUsersIds.map(users => users.id))]
      }

      this.setState(prevState => ({
        allUsers: this.state.tempAllUsers,
        displayAllUsers: this.state.tempAllUsers,
        displayUsers: this.state.tempUsersIds,
        usersIds: ids
      }));
    this.handleModalDisabled();
  }
  handlerGetDateValue(startDate, endDate, event){
    this.setState({
      startTime: startDate,
      endTime: endDate
    });
  }
  handlerGetStartTime(time, event){
    this.setState({
      startTimeCalendar: time
    });
  }
  handlerToggleUsersTeam(teamID, event) {
      const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
      const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
      const existingIds = new Set(this.state.getUsersIds.map(user => user.id));
      const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
      let updatedGetUsersIds;
      if (filteredUsers.length > 0) {
          updatedGetUsersIds = this.state.getUsersIds.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
      } else {
          updatedGetUsersIds = [...this.state.getUsersIds, ...newUsers];
      }
      this.setState({ getUsersIds: updatedGetUsersIds });
  }

  handlerHoverEnterUserTeam(teamID, event){
    const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
    const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
    const existingIds = new Set(this.state.getUsersIdsHover.map(user => user.id));
    const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
    let updatedGetUsersIds;
    if (filteredUsers.length > 0) {
        updatedGetUsersIds = this.state.getUsersIdsHover.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
    } else {
        updatedGetUsersIds = [...this.state.getUsersIdsHover, ...newUsers];
    }
    this.setState({ getUsersIdsHover: updatedGetUsersIds });
  }

  handlerHoverLeaveUserTeam(event){
    this.setState({ getUsersIdsHover: [] });
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('reminder-AddItemTitle') + ' | ' + this.props.companyName;
    }
    const reminderType = [
      { value: 'NONE', label: this.props.getTranslate('Reminder-Type_NONE') },
      { value: 'DAILY', label: this.props.getTranslate('Reminder-Type_DAILY') },
      { value: 'WORKING_DAY', label: this.props.getTranslate('Reminder-Type_WORKING_DAY') },
      { value: 'FIRST_DAY_OF_THE_MONTH', label: this.props.getTranslate('Reminder-Type_FIRST_DAY_OF_THE_MONTH') },
      { value: 'FIRST_WORKING_DAY_OF_THE_MONTH', label: this.props.getTranslate('Reminder-Type_FIRST_WORKING_DAY_OF_THE_MONTH') },
      { value: 'LAST_DAY_OF_THE_MONTH', label: this.props.getTranslate('Reminder-Type_LAST_DAY_OF_THE_MONTH') },
      { value: 'LAST_WORKING_DAY_OF_THE_MONTH', label: this.props.getTranslate('Reminder-Type_LAST_WORKING_DAY_OF_THE_MONTH') },
      { value: 'WEEKLY', label: this.props.getTranslate('Reminder-Type_WEEKLY') },
      { value: 'MONTHLY', label: this.props.getTranslate('Reminder-Type_MONTHLY') },
      { value: 'ONCE_IN_TWO_WEEK', label: this.props.getTranslate('Reminder-Type_ONCE_IN_TWO_WEEK') },
      { value: 'EVERY_QUARTER', label: this.props.getTranslate('Reminder-Type_EVERY_QUARTER') },
      { value: 'EVERY_YEAR', label: this.props.getTranslate('Reminder-Type_EVERY_YEAR') }
    ];
    let chooseUsers = '';
    if(this.state.displayUsers.length > 0 && !this.state.displayAllUsers){
      chooseUsers = this.state.displayUsers.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.name}{this.state.previewStatus ? '' : <span onClick={this.handlerRemoveUser.bind(this, item.id)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span>}</span>
      })
    }
    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ?       <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div></Scrollbars> : <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        <div className={`${this.state.displayAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">{this.props.getTranslate('modalAddUser-AllUsersLabel')}</span>
          </div>
          {this.state.displayAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label" onClick={this.handlerToggleUsersTeam.bind(this, item.teamID)} onMouseEnter={this.handlerHoverEnterUserTeam.bind(this, item.teamID)} onMouseLeave={this.handlerHoverLeaveUserTeam.bind(this)}>{item.name ? item.name : this.props.getTranslate('modalAddUser-NoTeamLabel')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.tempUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            let hoverActive = this.state.getUsersIdsHover && this.state.getUsersIdsHover.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
              return <div className={`${this.state.tempAllUsers || active || hoverActive ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {this.state.tempAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }
    let previewDateFull = null;
    if(this.state.objForSubmit){
      let previewDateHours = new Date(this.state.objForSubmit.startTime).getHours();
      let previewDateMinutes = new Date(this.state.objForSubmit.startTime).getMinutes();
      previewDateFull = (previewDateHours < 10 ? ('0' + previewDateHours) : previewDateHours) + ':' + (previewDateMinutes < 10 ? ('0' + previewDateMinutes) : previewDateMinutes);
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink("/account/reminder")} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        {this.state.previewStatus ?
            <div className="account-content__inner account-preview">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              preview={true}
              backBtn={true}
              backBtnType="btn"
              backBtnHandler={this.handlerNextPreview.bind(this)}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />
            <h2 className="account-preview__headline">{this.props.getTranslate('reminder-AddItemPreviewTitle')}</h2>

            <div className="account-preview__inner account-preview__inner_preview">
              <div className="account-preview__info">
                <div className="account-preview__wgt">
                  <div className="account-preview__wgt_row">
                    <div className="account-preview__wgt_col-info">
                      <div className="account-preview__wgt-headline">
                        <div className="account-preview__wgt-headline-text">{this.state.objForSubmit.name}</div>
                      </div>
                      <div className="account-preview__info-row">
                        <div className="account-preview__info-wgt account-preview__info-wgt-description">
                          <div className="account-preview__info-wgt-label">{this.props.getTranslate('reminder-labelDescription')}:</div>
                          <div className="account-preview__info-wgt-value" dangerouslySetInnerHTML={{__html: this.state.objForSubmit.description}}></div>
                        </div>
                        <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                          <div className="account-preview__info-wgt-label">{this.props.getTranslate('reminder-labelDate')}</div>
                          <div className="account-preview__info-wgt-value"><DateFormating start={this.state.objForSubmit.startTime} end={this.state.reminderType === 'NONE' ? false : this.state.objForSubmit.endTime} time="off" /></div>
                        </div>
                        <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                          <div className="account-preview__info-wgt-label">{this.props.getTranslate('reminder-labelTime')}</div>
                          <div className="account-preview__info-wgt-value">о {previewDateFull}</div>
                        </div>
                        <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                          <div className="account-preview__info-wgt-label">{this.props.getTranslate('reminder-Reccuring')}</div>
                          <div className="account-preview__info-wgt-value">{reminderType.find(item => item.value === this.state.objForSubmit.reminderType).label}</div>
                        </div>
                      </div>

                      <div className="account-preview__info-wgt">
                        <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('reminder-Users')}</label>
                          <div className="select-modal" onClick={this.handlerAddMembers.bind(this)}>
                          {this.state.displayUsers.length < 1 ?
                          <div className="select-users__input">
                          {this.state.displayAllUsers && this.state.displayUsers.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                                    <use href={`${sprite}#close-icon`}></use>
                                                  </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('polls-UsersFieldCaption')}</div>}
                        </div> : <div className="select-users__input">{chooseUsers}</div>}
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div className="account-preview__info-nav">
                    <button className="button-standart button-standart_theme_blue" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('marketplace-PreviewBtnEdit')}</button>
                    <button className="button-standart button-standart_theme_red" type="button">{this.props.getTranslate('marketplace-PreviewBtnDelete')}</button>
                  </div>
                </div>
                <button className="preview-submit-btn" type="button" onClick={this.handlerSubmitReminder.bind(this)}>{this.props.getTranslate('ads-SubmitPublish')}</button>
              </div>
              <div className="account-preview__img">
                <div className="account-preview__img-description">{this.props.getTranslate('event-EventPreviewExample')}</div>
                <img src={img_preview} alt="" />
              </div>
            </div>
            </div> : <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              backBtn={true}
              backBtnType="link"
              backBtnClass="button-back_theme_gray"
              backBtnHandler={this.props.getPermalink("/account/reminder")}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('reminder-AddItemTitle')}</h1>
          </div>
          <div className="event-form-wrap">
            <div className="event-form">
              <div className="event-form__inner">
                  <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('reminder-Caption')}</label>
                    </div>
                        <div className="field-wrap__input_width_75">
                          <input id="headline" className={`field-wrap__input ${(this.state.errorFields && !this.state.inputHeadline) || (this.state.errorFields && this.state.errorNameOnlySpace && this.state.inputHeadline !== '') || this.state.inputHeadlineLength ? 'field-wrap__input_error' : ''}`} type="text" onChange={this.handlerInputHeadline.bind(this)} value={this.state.inputHeadline} placeholder={this.props.getTranslate('reminder-CaptionFieldCaption')} />
                          {this.state.errorFields && !this.state.inputHeadline ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                          {this.state.errorFields && this.state.errorNameOnlySpace && this.state.inputHeadline !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                          {this.state.inputHeadlineLength ? <div className="validation-error">{this.props.getTranslate('validation-ReminderNameLength')}</div> : ''}
                        </div>
                  </div>
                <div className="field-wrap">
                  <div className="field-wrap__label-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('reminder-Description')}</label>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                        <p>{this.props.getTranslate('tooltipReminder-DescriptionReminder')}</p>
                      </div>
                    </div>
                  </div>
                  {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                    toolbar={{
                      options: ['inline', 'blockType', 'list'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline']
                      },
                      blockType: {
                        inDropdown: false,
                        options: [],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered']
                      }
                    }}
                    wrapperClassName={`wrapper-class editor-field ${this.state.errorDescriptionOnlySpace || this.state.errorDescriptionEmpty || this.state.errorLengthDescription ? 'field-wrap__input_error' : ''}`}
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class editor-toolbar"
                    handleBeforeInput={this.handleBeforeInput}
                    handlePastedText={this.handlePastedText}
                    editorState={this.state.contentState}
                    onEditorStateChange={this.onEditorStateChange}
                    onBlur={this.handleEditorBlur}
                  /> : ''}
                  {this.state.errorDescriptionEmpty ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {!this.state.errorDescriptionEmpty && this.state.errorDescriptionOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                  {this.state.errorLengthDescription ? <div className="validation-error">{this.props.getTranslate('validation-errorLengthDescriptionReminder')}</div> : ''}
                </div>

                <div className="event-form__row">
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('reminder-Reccuring')}</label>
                    <Select
                      styles={customStyles}
                      options={reminderType}
                      placeholder={this.props.getTranslate('events-ReccuringPlaceholder')}
                      value={this.state.reminderType ? reminderType[reminderType.findIndex(el => el.value === this.state.reminderType)] : null}
                      onChange={this.handlerReminderType.bind(this)}
                    />
                  </div>
                </div>

                  <CalendarDropdownInterval
                    label={this.props.getTranslate('reminder-Date')}
                    reminderType={this.state.reminderType === 'NONE'}
                    onChange={this.handlerGetDateValue.bind(this)}
                    onChangeTime={this.handlerGetStartTime.bind(this)}
                    calendarTime={this.state.startTimeCalendar}
                    getTranslate={this.props.getTranslate}
                    errorTxt={this.state.errorTime ? <div className="validation-error">{this.props.getTranslate('validation-RequiredFieldTime')}</div> : (this.state.errorDiffDate ? this.props.getTranslate('validation-errorDiffDate') : '')}
                  />
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('reminder-Users')}</label>
                    <div className="field-wrap__input_width_75">
                    <div className={`select-modal ${this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? 'field-wrap__input_error' : ''}`} onClick={this.handlerAddMembers.bind(this)}>
                    {this.state.displayUsers.length < 1 ?
                    <div className="select-users__input">
                    {this.state.displayAllUsers && this.state.displayUsers.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                              <use href={`${sprite}#close-icon`}></use>
                                            </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('polls-UsersFieldCaption')}</div>}
                  </div> : <div className="select-users__input">{chooseUsers}</div>}
                    </div>
                    </div>
                    {this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                </div>

                <div className="field-wrap__submit">
                  <button className="btn btn_size_lg" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('addReminder-buttonGoPreview')}</button>
                </div>

              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>

    {this.state.modal && this.state.modalAddMembers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="field-wrap field-wrap__search-members">
        <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
        <svg className="field-wrap__search-icon">
          <use href={`${sprite}#search-icon`}></use>
        </svg>
      </div>
      <div className="question-members">
        <div className="question-members-wgt">
          {users}
        </div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
          </div>
      </div>
    </div>
  </div> : ''}

    </>;
  }
}
export default ReminderCreate;
