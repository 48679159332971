import React from "react";
import Select from 'react-select';
import sprite from '../media/icons.svg';

const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#364ed4',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#364ed4',
    ':hover': {
      backgroundColor: '#e8ecff',
      color: '#364ed4',
    },
  })
};

const customStylesError = {
  ...customStyles,
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
};

// Функция для получения количества дней в месяце с учетом високосного года
const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

// Функция генерации списка дней
const generateDayOptions = (daysInMonth) => {
  return Array.from({ length: daysInMonth }, (_, i) => {
    const value = (i + 1).toString();
    return { label: value, value: value };
  });
};

// Функция генерации списка лет
const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;
  return Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
    const year = currentYear - i;
    return { label: year.toString(), value: year.toString() };
  });
};

class GetDateDropdownModal extends React.Component {
  constructor(props) {
    super(props);
    const currentYear = new Date().getFullYear();
    this.state = {
      modal: false,
      modalDisabled: false,
      dayValue: '',
      monthValue: '',
      yearValue: '',
      daysOptions: generateDayOptions(31)
    };
  }

  handleModalDisabled = () => {
    if (typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState({ modalDisabled: true });
    setTimeout(() => {
      this.setState({ modal: false, modalDisabled: false });
    }, 500);
  }

  handleModal = () => {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  updateDaysOptions = (year, month) => {

    const daysInMonth = getDaysInMonth(year, parseInt(month));
    const newDaysOptions = generateDayOptions(daysInMonth);

    this.setState((prevState) => ({
      daysOptions: newDaysOptions,
      dayValue: prevState.dayValue > daysInMonth ? daysInMonth.toString() : prevState.dayValue
    }));
  };


  handleGetDay = (event) => {
    this.setState({ dayValue: event.value });
  }

  handleGetMonth = (event) => {
    const newMonth = event.value;
    this.setState({ monthValue: newMonth }, () => {
      this.updateDaysOptions(this.state.yearValue, newMonth);
    });
  };


  handleGetYear = (event) => {
    const newYear = event.value;
    this.setState({ yearValue: newYear }, () => {
      this.updateDaysOptions(newYear, this.state.monthValue);
    });
  };

  render() {
    const monthes = [
      { label: 'Січень', value: '0' },
      { label: 'Лютий', value: '1' },
      { label: 'Березень', value: '2' },
      { label: 'Квітень', value: '3' },
      { label: 'Травень', value: '4' },
      { label: 'Червень', value: '5' },
      { label: 'Липень', value: '6' },
      { label: 'Серпень', value: '7' },
      { label: 'Вересень', value: '8' },
      { label: 'Жовтень', value: '9' },
      { label: 'Листопад', value: '10' },
      { label: 'Грудень', value: '11' }
    ];

    return <>
      <div className="user-statistic-table__add-btn" onClick={this.handleModal}>{this.props.btnName}</div>
      {this.state.modal && (
        <div id="modal-container" className={this.state.modalDisabled ? "two disabled modal-container" : "two modal-container"}>
          <div className="modal-background" onClick={this.handleModalDisabled}></div>
          <div className="modal">
            <div className="modal-header">
              <h2 className="modal-headline">{this.props.title}</h2>
              <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                <svg className="close-modal__icon">
                  <use href={`${sprite}#close-icon`}></use>
                </svg>
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-date-select">
                <label className="field-wrap__label field-wrap__label_theme_gray">Виберіть дату</label>
                <div className="modal-date-select__inner">
                  <div className="modal-date-select__field">
                    <Select
                      styles={this.props.errors.length > 0 ? customStylesError : customStyles}
                      options={this.state.daysOptions}
                      placeholder={"День"}
                      onChange={this.handleGetDay}
                      value={this.state.daysOptions.find(opt => opt.value === this.state.dayValue)}
                    />
                  </div>
                  <div className="modal-date-select__field">
                    <Select
                      styles={this.props.errors.length > 0 ? customStylesError : customStyles}
                      options={monthes}
                      placeholder={"Місяць"}
                      onChange={this.handleGetMonth}
                      value={monthes.find(opt => opt.value === this.state.monthValue)}
                    />
                  </div>
                  <div className="modal-date-select__field">
                    <Select
                      styles={this.props.errors.length > 0 ? customStylesError : customStyles}
                      options={generateYearOptions()}
                      placeholder={"Рік"}
                      onChange={this.handleGetYear}
                      value={generateYearOptions().find(opt => opt.value === this.state.yearValue)}
                    />
                  </div>
                </div>
              </div>
              <div className="field-wrap__submit">
                <button className="btn btn_width_100 btn_size_lg" type="button" onClick={() => this.props.handlerSubmitDate(`${this.state.yearValue}-${this.state.monthValue}-${this.state.dayValue}`, this.props.type)}>Зберегти</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  }
}

export default GetDateDropdownModal;
