import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Loading from './components/Loading';
import { Link, Navigate, useParams } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import CalendarDropdownInterval from './components/CalendarDropdownInterval';
import sprite from './media/icons.svg';
import img_preview from './media/item-preview-rewards.png';
import member_1 from './media/avatar-1.png';
import { getAuth } from "firebase/auth";
let Editor;
let EditorState;
let ContentState;
let convertFromHTML;
let stateToHTML;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;
  stateToHTML = require('draft-js-export-html').stateToHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class ReminderEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      previewStatus: false,
      headlineValue: '',
      contentState: '',
      modalDisabled: false,
      modal: false,
      getAllUsers: false,
      allUsers: false,
      getUsersIds: [],
      usersIds: [],
      chooseAllMembers: false,
      chooseMembersIds: [],
      reminderType: "NONE",
      startTime: null,
      endTime: null,
      startDateCalendar: null,
      endDateCalendar: null,
      startTimeCalendar: null,
      errorTime: false,
      getUsersIdsHover: [],
      errorFields: false,
      errorNameOnlySpace: false,
      errorDescriptionOnlySpace: false,
      errorTime: false,
      errorDescriptionEmpty: false,
      errorLengthDescription: false
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalData: {}
      }));
    }, 500);
  }

  handlerInputHeadline (event) {
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    if(event.target.value.length <= 100){
      this.setState({
        inputHeadline: event.target.value,
        inputHeadlineLength: false
      });
    } else {
      this.setState({
        inputHeadlineLength: true
      });
    }
  }


    handleEditorBlur = () => {
      const text = this.state.contentState.getCurrentContent().getPlainText();
      if (this.state.errorLengthDescription) {
        this.setState({
          errorLengthDescription: true
        });
      }
    };

    handleBeforeInput = (chars, editorState) => {
      const contentState = editorState.getCurrentContent();
      const text = contentState.getPlainText();

      if (text.length >= 500) {
        this.setState({
          errorLengthDescription: true
        });
        return 'handled';
      }
      return 'not-handled';
    };
    onEditorStateChange = (editorState) => {
      const currentContent = editorState.getCurrentContent();
      const text = currentContent.getPlainText();
      const rowLength = currentContent.getBlockMap().toArray();

      // Обновление высоты, если длина текста превышает лимит
      if (rowLength.length > 14) {
        const infoDiv = document.querySelector('.editor-class');
        const height = infoDiv.clientHeight;
        this.setState({
          heightInfo: height,
        });
      } else {
        this.setState({
          heightInfo: null,
        });
      }

      // Условие для ошибки длины
      if (text.length >= 500) {
        this.setState({
          errorLengthDescription: true,
        });
      } else {
        this.setState({
          errorLengthDescription: false,
        });
      }

      // Условие для проверки только пробелов
      if (this.state.errorDescriptionOnlySpace) {
        const blocks = currentContent.getBlockMap().toArray();
        const isOnlySpaces = blocks.every((block) => block.getText().trim() === '');
        if (!isOnlySpaces) {
          this.setState({
            errorDescriptionOnlySpace: false,
          });
        }
      }

      this.setState({
        contentState: editorState,
      });
    };


      handlePastedText = (text, html, editorState) => {
        const contentState = editorState.getCurrentContent();
        const pastedText = ContentState.createFromText(text);

        const totalLength = contentState.getPlainText().length + pastedText.getPlainText().length;

        if (totalLength > 500) {
          const truncatedText = pastedText.getPlainText();
          const newContentState = ContentState.createFromText(truncatedText);

          const newEditorState = EditorState.push(editorState, newContentState);

          this.onEditorStateChange(newEditorState);
          this.setState({
            errorLengthDescription: true
          });
          return 'handled';
        }

        const newEditorState = EditorState.push(editorState, pastedText);

        this.onEditorStateChange(newEditorState);
        return 'not-handled';
      };
  handlerReminderType (event) {
    this.setState({
      reminderType: event.value
    });
  }

  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    this.setState({
      rewardsID: this.props.id
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/reminders/${that.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            errors: data.message,
            error: true
          })
        });
      } else {
        let employees = null;
        res.json().then(function(data) {
          let startTimeCalendar = new Date(data.startTime);
          let startHour = startTimeCalendar.getHours();
          let startMinutes = startTimeCalendar.getMinutes();
          employees = data.employees;
          that.setState({
            inputHeadline: data.name,
            contentState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data.description)
              )
            ),
            allUsers: data.allUsers,
            employees: data.employees,
            usersIds: data.employees,
            reminderType: data.reminderType,
            startTime: data.startTime,
            endTime: data.endTime,
            startTimeCalendar: (startHour < 10 ? ('0' + startHour) : startHour) + ':' + (startMinutes < 10 ? ('0' + startMinutes) : startMinutes),
            isLoaded: true
          });
        });

        fetch(`${that.props.queryLink}/api/v1/users/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(
            {
              "itemsPerPage": -1
            }
          )
        }).then(res => {
          if(res.status === 200) {
            res.json().then(function(data) {
              let usersData = data.data;
              const getUsersIds = usersData
                .filter(userData => employees.includes(userData.userID))
                .map(userData => ({
                  id: userData.userID,
                  name: `${userData.name} ${userData.lastName}`
                }));
                that.setState({
                  getUsersIds: getUsersIds
                });
            });
          }
        })



        fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
              let users = data.map(x => x.users);
              let usersIDs = users.map(x => {
                return x.map(id => {
                  return {id: id.userID, name: id.name};
                })
              });
              var result3 = [].concat(...usersIDs);
              let ids = [...new Set(that.state.employees.map(users => users.userID))];
              var usersActive = result3.map(user => {
                if(ids.indexOf(user.id) !== -1){
                  return user;
                }
              });
              usersActive = usersActive.filter(function(x) {
                   return x !== undefined;
              });
              that.setState({
                usersGet: usersActive,
                getUsersIds: usersActive
              });
            })
          }
        })
      }
    })
  })
  }
  handlerAddMembers (event) {
    event.preventDefault();
    let that = this;
    if(event.target.tagName !== 'SPAN'){
      this.setState({
        modal: true,
        modalAddMembers: true,
        users: [],
        isLoadedUser: false,
        errorUsers: null
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                isLoadedUser: true,
                errorUsers: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedUser: true,
                users: data
              })
            });
          }
        })
      })
    }
  }
  handlerSearchUsers(event) {
    let arr = this.state.users;
    let newArr = [];
    this.setState({
      searchValue: event.target.value,
      usersSearch: []
    });
    let that = this;
    arr.map(function(item) {
      item.users.map(function(users) {
        if (users.name.includes(event.target.value)) {
           newArr.push(users);
          that.setState(prevState => ({
            usersSearch: [...prevState.usersSearch, users]
          }));
        }
      })
    });
  }
  handlerSetUsers(){
      let ids = [];
      if(this.state.getUsersIds){
        ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
      }
      this.setState(prevState => ({
        allUsers: this.state.getAllUsers ? true : false,
        usersIds: ids
      }));
    this.handleModalDisabled();
  }
  handlerGetAllUsers(){
    this.setState(prevState => ({
      getAllUsers: !prevState.getAllUsers,
      getUsersIds: [],
    }));
  }
  handlerToggleUser(index, name, id){
    if(this.state.getAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {id: id, name: name}];
    }
    this.setState(prevState => ({
      getUsersIds: arr
    }));
  }
  handlerRemoveAllUsers(){
    let data = this.state.resultData;
    data.allUsers = false;
    data.users = [];
    this.setState(prevState => ({
      getAllUsers: false,
      allUsers: false
    }));
  }
  handlerRemoveUser(id){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))]
    }
    this.setState(prevState => ({
      getUsersIds: arr,
      usersIDs: ids
    }));
  }
  handlerGetDateValue(startDate, endDate, event){
    this.setState({
      startTime: startDate,
      endTime: endDate,
      startDateCalendar: startDate,
      endDateCalendar: endDate
    });
  }
  handlerGetStartTime(time, event){
    this.setState({
      startTimeCalendar: time
    });
  }

  handlerReminderSubmit (event) {
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedSubmit: true
    });
    let location;
let language = 'uk-UA';
if(typeof window !== "undefined" && typeof document !== 'undefined') {
location = document.location.pathname;
const isEnglish = location.startsWith('/en');
if (isEnglish) {
language = 'en-US'
}
}
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token(),
        'Accept-Language': language
    });
    let obj = {
      "reminderID": this.props.id,
      "allUsers": that.state.allUsers,
      "description": stateToHTML(this.state.contentState.getCurrentContent()),
      "employees": that.state.getUsersIds.map(user => user.id),
      "name": that.state.inputHeadline,
      "reminderType": this.state.reminderType
    };
    if(that.state.startTime){
      let date = new Date(that.state.startTime);
      let start = that.state.startTimeCalendar;
      let [hours, minutes] = start.split(':').map(Number);

      date.setHours(hours, minutes, 0, 0);
      obj.startTime = date.toISOString();
    }
    if(that.state.endTime){
      obj.endTime = new Date(that.state.endTime).toISOString();
    } else {
      const date = new Date();
      date.setHours(23, 59, 59, 999);
      obj.endTime = date.toISOString();
    }
    fetch(`${that.props.queryLink}/api/v1/reminders/${that.props.id}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(obj)
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedSubmit: false,
            errors: data.errors ? data.errors : data
          })
        })
      } else {
          that.setState({
            isLoadedSubmit: false,
            successSubmit: true
          });
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            localStorage.setItem("successReminderSubmit", JSON.stringify({status: that.state.successSubmit, text: 'Успішно відредаговано'}))
          }
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
      }
    })
  }

  handlerToggleUsersTeam(teamID, event) {
      const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
      const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
      const existingIds = new Set(this.state.getUsersIds.map(user => user.id));
      const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
      let updatedGetUsersIds;
      if (filteredUsers.length > 0) {
          updatedGetUsersIds = this.state.getUsersIds.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
      } else {
          updatedGetUsersIds = [...this.state.getUsersIds, ...newUsers];
      }
      this.setState({ getUsersIds: updatedGetUsersIds });
  }

  handlerHoverEnterUserTeam(teamID, event){
    const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
    const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
    const existingIds = new Set(this.state.getUsersIdsHover.map(user => user.id));
    const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
    let updatedGetUsersIds;
    if (filteredUsers.length > 0) {
        updatedGetUsersIds = this.state.getUsersIdsHover.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
    } else {
        updatedGetUsersIds = [...this.state.getUsersIdsHover, ...newUsers];
    }
    this.setState({ getUsersIdsHover: updatedGetUsersIds });
  }

  handlerHoverLeaveUserTeam(event){
    this.setState({ getUsersIdsHover: [] });
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('reminder-EditItemTitle') + ' | ' + this.props.companyName;
    }
    const reminderType = [
      { value: 'NONE', label: this.props.getTranslate('Reminder-Type_NONE') },
      { value: 'DAILY', label: this.props.getTranslate('Reminder-Type_DAILY') },
      { value: 'WORKING_DAY', label: this.props.getTranslate('Reminder-Type_WORKING_DAY') },
      { value: 'FIRST_DAY_OF_THE_MONTH', label: this.props.getTranslate('Reminder-Type_FIRST_DAY_OF_THE_MONTH') },
      { value: 'FIRST_WORKING_DAY_OF_THE_MONTH', label: this.props.getTranslate('Reminder-Type_FIRST_WORKING_DAY_OF_THE_MONTH') },
      { value: 'LAST_DAY_OF_THE_MONTH', label: this.props.getTranslate('Reminder-Type_LAST_DAY_OF_THE_MONTH') },
      { value: 'LAST_WORKING_DAY_OF_THE_MONTH', label: this.props.getTranslate('Reminder-Type_LAST_WORKING_DAY_OF_THE_MONTH') },
      { value: 'WEEKLY', label: this.props.getTranslate('Reminder-Type_WEEKLY') },
      { value: 'MONTHLY', label: this.props.getTranslate('Reminder-Type_MONTHLY') },
      { value: 'ONCE_IN_TWO_WEEK', label: this.props.getTranslate('Reminder-Type_ONCE_IN_TWO_WEEK') },
      { value: 'EVERY_QUARTER', label: this.props.getTranslate('Reminder-Type_EVERY_QUARTER') },
      { value: 'EVERY_YEAR', label: this.props.getTranslate('Reminder-Type_EVERY_YEAR') }
    ];
    let chooseUsers = '';
    if(this.state.getUsersIds && this.state.getUsersIds.length > 0 && !this.state.allUsers){
      chooseUsers = this.state.getUsersIds.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.name}<span onClick={this.handlerRemoveUser.bind(this, item, index)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span></span>
      })
    }
    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ?       <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div></Scrollbars> : <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        <div className={`${this.state.getAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">Все</span>
          </div>
          {this.state.getAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label" onMouseEnter={this.handlerHoverEnterUserTeam.bind(this, item.teamID)} onMouseLeave={this.handlerHoverLeaveUserTeam.bind(this)}>{item.name ? item.name : this.props.getTranslate('modalAddUser-NoTeamLabel')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            let hoverActive = this.state.getUsersIdsHover && this.state.getUsersIdsHover.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
              return <div className={`${this.state.getAllUsers || active || hoverActive ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {this.state.getAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent =           <div className="event-form-wrap">
                  <div className="event-form">
                    <div className="event-form__inner">
                        <div className="field-wrap">
                          <div className="field-wrap__label-wrap">
                            <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('reminder-Caption')}</label>
                          </div>
                              <div className="field-wrap__input_width_75">
                                <input id="headline" className={`field-wrap__input`} type="text" onChange={this.handlerInputHeadline.bind(this)} value={this.state.inputHeadline} placeholder={this.props.getTranslate('reminder-CaptionFieldCaption')} />
                              </div>
                        </div>
                      <div className="field-wrap">
                        <div className="field-wrap__label-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('reminder-Description')}</label>
                          <div className="tooltip-info">
                            <span className="tooltip-info__icon">?</span>
                            <div className="tooltip-info__content">
                              <div className="arrow"></div>
                              <p>{this.props.getTranslate('tooltipReminder-DescriptionReminder')}</p>
                            </div>
                          </div>
                        </div>
                        {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                          toolbar={{
                            options: ['inline', 'blockType', 'list'],
                            inline: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ['bold', 'italic', 'underline']
                            },
                            blockType: {
                              inDropdown: true,
                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                            },
                            list: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ['unordered', 'ordered']
                            }
                          }}
                          wrapperClassName={`wrapper-class editor-field ${this.state.errorDescriptionOnlySpace ? 'field-wrap__input_error' : ''}`}
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class editor-toolbar"
                          handleBeforeInput={this.handleBeforeInput}
                          handlePastedText={this.handlePastedText}
                          editorState={this.state.contentState}
                          onEditorStateChange={this.onEditorStateChange}
                          onBlur={this.handleEditorBlur}
                        /> : ''}
                      </div>

                      <div className="event-form__row">
                        <div className="field-wrap">
                          <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('reminder-Reccuring')}</label>
                          <Select
                            styles={customStyles}
                            options={reminderType}
                            placeholder={this.props.getTranslate('reminder-ReccuringPlaceholder')}
                            value={this.state.reminderType ? reminderType[reminderType.findIndex(el => el.value === this.state.reminderType)] : null}
                            onChange={this.handlerReminderType.bind(this)}
                          />
                        </div>
                      </div>

                        <CalendarDropdownInterval
                          label={this.props.getTranslate('events-Date')}
                          reminderType={this.state.reminderType === 'NONE'}
                          onChange={this.handlerGetDateValue.bind(this)}
                          onChangeTime={this.handlerGetStartTime.bind(this)}
                          calendarTime={this.state.startTimeCalendar}
                          errorTxt={this.state.errorTime ? <div className="validation-error">{this.props.getTranslate('validation-RequiredFieldTime')}</div> : ''}
                          startTime={this.state.startTime}
                          getTranslate={this.props.getTranslate}
                          endTime={this.state.endTime}
                        />

                        <div className="field-wrap field-wrap__members">
                          <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('reminder-Users')}</label>
                          <div className={`select-modal ${this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? 'field-wrap__input_error' : ''}`} onClick={this.handlerAddMembers.bind(this)}>
                          {this.state.usersIds.length < 1 ?
                          <div className="select-users__input">
                            {this.state.allUsers && this.state.usersIds.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                                      <use href={`${sprite}#close-icon`}></use>
                                                    </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('getEmployees-FieldSelectPlaceholder')}</div>}
                          </div> : <div className="select-users__input">{chooseUsers}</div>}
                          </div>
                          {this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                        </div>

                      <div className="field-wrap__submit">
                        <button className="btn btn_size_lg" type="button" onClick={this.handlerReminderSubmit.bind(this)}>Зберегти</button>
                      </div>

                    </div>
                  </div>
                </div>;
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {this.state.successSubmit && <Navigate to={this.props.getPermalink('/account/reminder')} replace={true} />}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} token={this.props.token()} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
            backBtn={true}
            backBtnType="link"
            backBtnClass="button-back_theme_gray"
            backBtnHandler={this.props.getPermalink("/account/reminder")}
            backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
          />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('reminder-EditItemTitle')}</h1>
          </div>
          {resultContent}
        </div>
      </div>
    </div>

    {this.state.modal && this.state.modalAddMembers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="field-wrap field-wrap__search-members">
        <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
        <svg className="field-wrap__search-icon">
          <use href={`${sprite}#search-icon`}></use>
        </svg>
      </div>
      <div className="question-members">
        <div className="question-members-wgt">
          {users}
        </div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
          </div>
      </div>
    </div>
  </div> : ''}

    </>;
  }
}
export default (props) => <ReminderEdit {...useParams()} {...props} />
