import React from "react";
import Button from './Button';
class OrderModalComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStatus: false,
      clickedInside: false,
      noteValue: '',
      sendMessage: false
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClick() {
    this.setState((prevState) => ({
      activeStatus: !prevState.activeStatus,
      clickedInside: true,
    }));
  }
  handlerDropDownStatus(event) {
    this.setState(prevState => ({
      activeStatus: !prevState.activeStatus
    }));
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.state.clickedInside) {
      this.setState({
        activeStatus: false,
      });
    }
    this.setState({ clickedInside: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handlerNoteValue(event){
    this.setState({
      noteValue: event.target.value
    });
  }
  handlerSendMessage(event) {
    this.setState(prevState => ({
      sendMessage: !prevState.sendMessage
    }));
  }
  render() {
    if(this.props.restoreFromSubmit){
      setTimeout(() => {
        this.setState(prevState => ({
          activeStatus: false,
          noteValue: '',
          sendMessage: false
        }));
      }, 10)
    }
      return     <div className="modal__order-nav-dropdown" ref={this.setWrapperRef}>
                  <button className={this.state.activeStatus ? 'modal__order-nav-button modal__order-nav-button-dropdown active' : 'modal__order-nav-button modal__order-nav-button-dropdown'} type="button" onClick={this.handlerDropDownStatus.bind(this)}>{this.props.title}</button>
                  <div className={this.state.activeStatus ? 'modal__order-nav-content-dropdown modal__order-nav-content-dropdown-comment active' : 'modal__order-nav-content-dropdown modal__order-nav-content-dropdown-comment'}>
                      <textarea className={`modal__order-comment-field${this.props.errors?.errorFields ? ' field-wrap__input_error' : ''}`} value={this.state.noteValue} onChange={this.handlerNoteValue.bind(this)}/>
{this.props.errors?.errorFields && (
  <>
    {typeof this.props.errors.errorNoteEmpty !== 'undefined' && (
      <div className="validation-error">
        {this.props.errors.errorNoteEmpty || ''}
      </div>
    )}
    {typeof this.props.errors.errorNoteOnlySpace !== 'undefined' && (
      <div className="validation-error">
        {this.props.errors.errorNoteOnlySpace || ''}
      </div>
    )}
  </>
)}

                      <div className="modal__order-send-message">
                        <input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" checked={this.state.sendMessage} onChange={this.handlerSendMessage.bind(this)} />
                        <label htmlFor="styled-checkbox-0">{this.props.checkboxLabel} {this.props.userName.length > 30
  ? this.props.userName.slice(0, 30) + "..."
  : this.props.userName}</label>
                      </div>
                      <div className="field-wrap__submit field-wrap__submit-padding-none">
                        <Button type="button" btnSize="large" btnWidth="full" text={this.props.buttonSubmitText} loader={this.props.loader} handler={this.props.handler.bind(this, this.props.id, this.state.noteValue, this.state.sendMessage)} />
                      </div>
                  </div>
                </div>
  }
}
export default OrderModalComment;
