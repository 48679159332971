import React from "react";
import Loading from './components/Loading';
import AccountContentHeader from './components/AccountContentHeader';
import DashboardWgt from './components/Dashboard/DashboardWgt';
import DashboardStatisticWgt from './components/Dashboard/DashboardStatisticWgt';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import { Link, Navigate } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      activeNotification: false,
      orders: null,
      bonuses: null,
      messages: null,
      users: null,
      ads: null,
      loyalties: null,
      events: null,
      products: null,
      surveys: null,
      newOrders: null,
      newAds: null,
      books: null,
      isLoaded: false,
      currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
      errors: null,
      error: false,
      successConfirm: null
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerDashboardNav(status, event){
    event.preventDefault();
    this.setState( {activeNotification: status} )
  }
  componentDidMount() {
    if(localStorage.getItem('successConfirm')){
      this.setState({
        successConfirm: {status: true, text: 'Ваш аккаунт успішно підтверджений'}
      });
      localStorage.removeItem('successConfirm');
    }
    if (typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
      let currentUser = getAuth().currentUser;
        if (currentUser) {
      currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
            fetch(`${that.props.queryLink}/api/v1/users/dashboards`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    isLoaded: true,
                    errors: data.errors,
                    error: true
                  });
                })
              } else {
                res.json().then(function(data) {
                  that.setState({
                    isLoaded: true,
                    bonuses: data.bonuses,
                    orders: data.orders,
                    messages: data.messages,
                    users: data.users,
                    ads: data.ads,
                    loyalties: data.loyalties,
                    events: data.events,
                    products: data.products,
                    surveys: data.surveys,
                    newOrders: data.newOrders,
                    newAds: data.newAds,
                    books: data.books
                  });
                })
              }
            })
      });
        }
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successConfirm: null
    }));
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('dashbord-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.successConfirm){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      const statisticsArr = [
        {
          value: this.props.number_format(this.state.bonuses, 0, '', ' ') + ' ' + (this.props.currency ? ' ' + this.props.currency : ''),
          headline: this.props.getTranslate('dashbord-BonusCount')
        },
        {
          value: this.state.orders,
          headline: this.props.getTranslate('dashbord-Orders'),
        },
        {
          value: this.state.messages,
          headline: this.props.getTranslate('dashbord-NewMessages')
        },
        {
          value: 0,
          headline: this.props.getTranslate('dashbord-Notifications')
        }
      ];
      const dashboardArr = [
        {
          to: this.props.getPermalink("/account/users"),
          icon: "#dashboard-team",
          headline: this.props.getTranslate('dashbord-Employees'),
          value: this.state.users,
          valueHeadline: this.props.getTranslate('dashbord-EmployeesCount'),
          status: true
        },
        {
          to: this.props.getPermalink("/account/products"),
          icon: "#dashboard-catalog",
          headline: this.props.getTranslate('dashbord-Catalog'),
          value: this.state.products,
          valueHeadline: this.props.getTranslate('dashbord-CatalogItems'),
          status: (this.props.configsApp && this.props.configsApp.shop === true)
        },
        {
          to: this.props.getPermalink("/account/orders"),
          icon: "#dashboard-orders",
          headline: this.props.getTranslate('dashbord-Orders'),
          newValueCount: this.state.newOrders,
          value: this.state.orders,
          valueHeadline: this.props.getTranslate('dashbord-OrdersCount'),
          status: true
        },
        {
          to: this.props.getPermalink("/account/surveys/default"),
          icon: "#dashboard-polls",
          headline: this.props.getTranslate('dashbord-Polls'),
          value: this.state.surveys,
          valueHeadline: this.props.getTranslate('dashbord-PollsCount'),
          status: (this.props.configsApp && this.props.configsApp.survey === true)
        },
        {
          to: this.props.getPermalink("/account/benefits"),
          icon: "#dashboard-bonuses",
          headline: this.props.getTranslate('dashbord-Bonuses'),
          value: this.state.loyalties,
          valueHeadline: this.props.getTranslate('dashbord-BonusesCount'),
          status: (this.props.configsApp && this.props.configsApp.loyalty === true)
        },
        {
          to: this.props.getPermalink("/account/marketplace"),
          icon: "#dashboard-ads",
          headline: this.props.getTranslate('dashbord-Marketplace'),
          value: this.state.ads,
          valueHeadline: this.props.getTranslate('dashbord-MarketplaceCount'),
          newValueCount: this.state.newAds,
          status: (this.props.configsApp && this.props.configsApp.ad === true)
        },
        {
          to: this.props.getPermalink("/account/events"),
          icon: "#dashboard-events",
          headline: this.props.getTranslate('dashbord-Events'),
          value: this.state.events,
          valueHeadline: this.props.getTranslate('dashbord-EventsCount'),
          status: (this.props.configsApp && this.props.configsApp.event === true)
        },
        {
          to: this.props.getPermalink("/account/library"),
          icon: "#dashboard-library",
          headline: this.props.getTranslate('dashbord-Library'),
          value: this.state.books,
          valueHeadline: this.props.getTranslate('dashbord-LibraryCount'),
          status: (this.props.configsApp && this.props.configsApp.library === true)
        }
      ];
      resultContent = <div className="account-content__content dashboard-content">
                  <div className="dashboard-mob-nav">
                    <ul className="dashboard-mob-nav__list">
                      <li className="dashboard-mob-nav__item">
                        <button className={`dashboard-mob-nav__button ${this.state.activeNotification ? '' : 'active'}`} type="button" onClick={this.handlerDashboardNav.bind(this, false)}>{this.props.getTranslate('dashbord-Title')}</button>
                      </li>
                      <li className="dashboard-mob-nav__item">
                        <button className={`dashboard-mob-nav__button ${this.state.activeNotification ? 'active' : ''}`} type="button" onClick={this.handlerDashboardNav.bind(this, true)}>{this.props.getTranslate('dashbord-AllNotification')}<span className="dashboard-notifications__header-amount">0</span></button>
                      </li>
                    </ul>
                  </div>
                  <div className={`dashboard-content__inner ${this.state.activeNotification ? '' : 'active'}`}>
                    <div className="dashboard-content__statistics">
                      <div className="dashboard-content__statistics-inner">
                      {statisticsArr.map((item, index) => {
                        return <DashboardStatisticWgt
                          key={index}
                          value={item.value}
                          headline={item.headline}
                        />
                      })}
                      </div>
                    </div>
                    <div className="dashboard-wgts">
                      {dashboardArr.map((item, index) => {
                        return <DashboardWgt
                          key={index}
                          to={item.to}
                          icon={item.icon}
                          headline={item.headline}
                          value={item.value}
                          valueHeadline={item.valueHeadline}
                          newValueCount={item.newValueCount}
                          status={item.status}
                        />
                      })}
                    </div>
                  </div>
                  <div className={`dashboard-notifications ${this.state.activeNotification ? 'active' : ''}`}>
                    <div className="dashboard-notifications__header">
                      <div className="dashboard-notifications__header-inner">
                        <h2 className="account-content__headline account-content__headline-notification">{this.props.getTranslate('dashbord-AllNotification')}</h2>
                        <div className="dashboard-notifications__header-amount">0</div>
                        <Link className="dashboard-notifications__link" to={this.props.getPermalink("/account/notification")}>
                          <svg className="dashboard-notifications__icon">
                            <use href={`${sprite}#setting-icon`}></use>
                          </svg>
                        </Link>
                      </div>
                      <button className="dashboard-notifications__clear" type="button">{this.props.getTranslate('dashbord-AllNotificationClear')}</button>
                    </div>
                  </div>
                </div>;
    }
    return <>
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (!this.props.token() ? <Navigate to={this.props.getPermalink("/login")} replace={true} /> : '') : ''}

    {/* Query result handler */}
    {this.state.successConfirm && this.state.successConfirm.status ? <ResultQuery
      success={this.state.successConfirm.status}
      resultText={this.state.successConfirm.text}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    /> : ''}

    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            title={this.props.getTranslate('dashbord-Title')}
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
          />

          {/* Query result */}
          {resultContent}

        </div>
      </div>
    </div>
    </>;
  }
}
export default Dashboard;
