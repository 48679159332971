import React from "react";
import Button from '../Button';
import DemoModal from '../../components/DemoModal';
import ScrollingList from './ScrollingList';
import avatar_1 from '../../media/avatars/avatar-1.png';
import avatar_2 from '../../media/avatars/avatar-2.png';
import avatar_3 from '../../media/avatars/avatar-3.png';
import avatar_4 from '../../media/avatars/avatar-4.png';
import avatar_6 from '../../media/avatars/avatar-6.png';
import avatar_7 from '../../media/avatars/avatar-7.png';
import avatar_8 from '../../media/avatars/avatar-8.png';
import avatar_9 from '../../media/avatars/avatar-9.png';
import avatar_10 from '../../media/avatars/avatar-10.png';
import avatar_11 from '../../media/avatars/avatar-11.png';
import avatar_12 from '../../media/avatars/avatar-12.png';
import avatar_13 from '../../media/avatars/avatar-13.png';
import avatar_14 from '../../media/avatars/avatar-14.png';
import avatar_15 from '../../media/avatars/avatar-15.png';
import avatar_16 from '../../media/avatars/avatar-16.png';
import avatar_17 from '../../media/avatars/avatar-17.png';
import avatar_18 from '../../media/avatars/avatar-18.png';
import avatar_19 from '../../media/avatars/avatar-19.png';
import avatar_20 from '../../media/avatars/avatar-20.png';
import avatar_21 from '../../media/avatars/avatar-21.png';
import avatar_22 from '../../media/avatars/avatar-22.png';
import avatar_23 from '../../media/avatars/avatar-23.png';
import avatar_24 from '../../media/avatars/avatar-24.png';
import avatar_25 from '../../media/avatars/avatar-25.png';
import avatar_26 from '../../media/avatars/avatar-26.png';
import avatar_27 from '../../media/avatars/avatar-27.png';
import avatar_28 from '../../media/avatars/avatar-28.png';
import avatar_29 from '../../media/avatars/avatar-29.png';
import avatar_30 from '../../media/avatars/avatar-30.png';
import avatar_31 from '../../media/avatars/avatar-31.png';
import avatar_32 from '../../media/avatars/avatar-32.png';
import avatar_33 from '../../media/avatars/avatar-33.png';
import avatar_34 from '../../media/avatars/avatar-34.png';
import avatar_35 from '../../media/avatars/avatar-35.png';
import avatar_36 from '../../media/avatars/avatar-36.png';
import green_phone from '../../media/PhoneGreen.png';
class HomeTry extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let headlineTry = this.props.getTranslate('homeTry-Headline');
    return <section className="home-try">
      <div className="wrapper">
        <div className="home-try__inner">
        <img className="home-try__phone" src={green_phone} alt={headlineTry} />
        <div className="home-try__avatarts-wrapper">
          <div className="home-try__avatars">
            <ul className="home-try-avatars-list">
              <ScrollingList items={[avatar_1, avatar_2, avatar_3, avatar_4, avatar_6, avatar_7, avatar_8, avatar_9, avatar_10, avatar_11, avatar_12, avatar_13, avatar_14, avatar_15, avatar_16, avatar_17, avatar_18, avatar_19, avatar_20, avatar_21, avatar_22, avatar_23, avatar_24, avatar_25, avatar_26, avatar_27, avatar_28, avatar_29, avatar_30, avatar_31, avatar_32, avatar_33, avatar_34, avatar_35, avatar_36]} />
            </ul>
          </div>
            <div className="home-try__avatars">
              <ul className="home-try-avatars-list">
                <ScrollingList items={[avatar_19, avatar_20, avatar_21, avatar_22, avatar_23, avatar_24, avatar_25, avatar_26, avatar_27, avatar_28, avatar_29, avatar_30, avatar_31, avatar_32, avatar_33, avatar_34, avatar_35, avatar_36, avatar_1, avatar_2, avatar_3, avatar_4, avatar_6, avatar_7, avatar_8, avatar_9, avatar_10, avatar_11, avatar_12, avatar_13, avatar_14, avatar_15, avatar_16, avatar_17]} />
              </ul>
            </div>
          </div>
          <div className="home-try__content">
            <div className="main-headline-wrap">
              <h2 className="main-headline">{this.props.getTranslate('homeTry-Headline')}</h2>
            </div>
            <div className="home-try__description">
              <p>{this.props.getTranslate('homeTry-Description')}</p>
            </div>
            <div className="home-try__nav">
              <div className="home-try__nav-col">
                <Button className="home-try__nav-btn home-try__nav-btn_theme_blue" type="link" text={this.props.getTranslate('index-RegCompanyButton')} btnWidth="full" handler="/registration" />
              </div>
              <div className="home-try__nav-col">
                <DemoModal
                  openBtnClass="home-try__nav-btn home-try__nav-btn_theme_blue-border btn"
                  getTranslate={this.props.getTranslate}
                  queryLink={this.props.queryLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}
export default HomeTry;
