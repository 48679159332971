import React from "react";
import sprite from '../media/icons.svg';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeInput: false,
      typingTimeout: null,
      valueInput: ''
    }
  }

  handleInputChange = (e) => {
    this.setState(prevState => ({
      changeInput: true
    }));
    // Получаем значение из инпута, удаляя все нецифровые символы
    const value = e.target.value.replace(/\D/g, '');

    // Очищаем предыдущий таймер, если он существует
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    // Обновляем состояние для ввода и создаем новый таймер
    this.setState({
      changeInput: true, // Устанавливаем флаг для отслеживания ввода
      valueInput: value,  // Обновляем введенное значение
      typingTimeout: setTimeout(() => {
        const pageNumber = parseInt(value, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= this.props.total) {
          // Запускаем пагинацию только после 1 секунды без изменений
          this.hanlderPagination(pageNumber - 1);
        }
      }, 1000) // Задержка 1 секунда после завершения ввода
    });
  };



  hanlderPagination (i, event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    let data = {
      "itemsPerPage": that.props.perPage,
      "page": i
    }
    let params = that.props.otherParams;
    if(params) {
      data = {...data, ...params}
    }
    fetch(`${that.props.path}`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then((result) => {
      that.props.items(result.data, result.page);
      },
      (error) => {

      }
    )
  })
  }
  handlerPaginationPrev (event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    let data = {
      "itemsPerPage": that.props.perPage,
      "page": that.props.currentPage - 1
    }
    let params = that.props.otherParams;
    if(params) {
      data = {...data, ...params}
    }
    fetch(`${that.props.path}`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then((result) => {
      that.props.items(result.data, that.props.currentPage - 1);
      },
      (error) => {

      }
    )
  })
  }
  handlerPaginationNext (event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    let data = {
      "itemsPerPage": that.props.perPage,
      "page": that.props.currentPage + 1
    }
    let params = that.props.otherParams;
    if(params) {
      data = {...data, ...params}
    }
    fetch(`${that.props.path}`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then((result) => {
      that.props.items(result.data, that.props.currentPage + 1);
      },
      (error) => {

      }
    )
  })
  }
  render() {
    let itemsPagination = [];
    for(let i = 0; i < this.props.total; i++){
      if (i < 3 || i === this.props.total - 1) {
        itemsPagination.push(
          <li className="pagination__item" key={i}>
            <button className={`pagination__link ${this.props.currentPage === i ? 'active' : '' }`} type="button" onClick={this.hanlderPagination.bind(this, i)}>{i + 1}</button>
          </li>
        );
      }
      if(i === 3){
        itemsPagination.push(
        <li className="pagination__item">
        <input
          className="pagination__input"
          min="1"
          max={this.props.total}
          value={this.state.changeInput ? this.state.valueInput : '...'}
          onChange={this.handleInputChange}
        />

        </li>
        );
      }
    }
    return (
      <>
        {this.props.total > 1 && (
          <nav className="pagination-nav">
            <ul className="pagination">
              <li className="pagination__item">
                <button className="pagination__link pagination__link_prev" onClick={this.handlerPaginationPrev.bind(this)} type="button">
                  <svg>
                    <use href={`${sprite}#left-icon`}></use>
                  </svg>
                </button>
              </li>
              {itemsPagination}
              <li className="pagination__item">
                {this.props.total - 1 > this.props.currentPage ? (
                  <button className="pagination__link pagination__link_next" onClick={this.handlerPaginationNext.bind(this)} type="button">
                    <svg>
                      <use href={`${sprite}#right-icon`}></use>
                    </svg>
                  </button>
                ) : (
                  <button className="pagination__link pagination__link_next" type="button">
                    <svg>
                      <use href={`${sprite}#right-icon`}></use>
                    </svg>
                  </button>
                )}
              </li>
            </ul>
          </nav>
        )}
      </>
    );
}
}
export default Pagination;
