import React from "react";
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import ResultErrors from './components/ResultErrors';
import Navigation from './components/Navigation';
import Pagination from './components/Pagination';
import Loading from './components/Loading';
import sprite from './media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class ControlPanelTranslations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      modalDisabled: false,
      modal: false,
      modalCreate: false,
      searchField: false,
      translationsData: [],
      englishValue: '',
      ukrainianValue: '',
      valueTranslationsAddKey: '',
      englishTranslationsAddValue: '',
      ukrainianTranslationsAddValue: '',
      valueTranslationsAddType: '',
      translationID: null,
      labelValue: null,
      translationType: null,
      translationIndex: null,
      isLoadedSubmit: false,
      totalPages: null,
      currentPage: 0,
      searchEmpty: false,
      timer: null,
      isLoaded: false,
      error: false,
      errors: null,
      controlRow: null,
      modalDelete: false,
      modalDeleteIndex: null,
      modalDeleteId: null,
      labelEditModal: null,
      token: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("token-admin") ? JSON.parse(localStorage.getItem("token-admin")) : JSON.parse('')) : ''
    }
  }
  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modal: false,
        modalCreate: false,
        modalDelete: false,
        modalDeleteIndex: null,
        modalDeleteId: null,
        labelEditModal: null
      }));
    }, 500);
  }
  handlerPaginationItems = (translationItems, current) => {
    this.setState({
      translationsData: translationItems,
      currentPage: current
    });
  }
  handlerGetModal(id, index, event) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/translations/${id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            englishValue: data.english,
            ukrainianValue: data.ukrainian,
            labelValue: data.label,
            translationID: data.translationID,
            translationType: data.translationType,
            translationIndex: index,
            labelEditModal: data.label
          });
        })
        that.setState(prevState => ({
          modal: !prevState.modal
        }));
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message
          });
        })
      }
    })
  })
  }
  handlerGetModalCreate(event) {
    let that = this;
    that.setState(prevState => ({
      modalCreate: !prevState.modalCreate
    }));
  }
  componentDidMount() {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/bo/v1/configs/translations/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "itemsPerPage": 20,
        "orderField": "creationDate",
      })
    }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            translationsData: data.data,
            totalPages: data.totalPages,
            isLoaded: true
          });
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  })
  }
  handlerTranslationsValue(language, event){
    if(language === 'english'){
      this.setState({
        englishValue: event.target.value
      });
    } else {
      this.setState({
        ukrainianValue: event.target.value
      });
    }
  }
  handlerKeyValue(event){
    this.setState({
      labelValue: event.target.value
    });
  }
  handlerTypeValue(event){
    this.setState({
      translationType: event.value
    });
  }
  handlerSubmit(event){
    let that = this;
    let error = false;
    event.preventDefault();
    that.setState({
      isLoadedSubmit: true,
      errorFields: false,
      errorEnglishValueOnlySpaces: false,
      ukrainianEnglishValueOnlySpaces: false
    });
    let englishValue = this.validItemEmpty('englishValue');
    if(/^\s*$/.test(englishValue) && englishValue !== ''){
      error = true;
      this.setState({errorEnglishValueOnlySpaces: true });
    }
    let ukrainianValue = this.validItemEmpty('ukrainianValue');
    if(/^\s*$/.test(ukrainianValue) && ukrainianValue !== ''){
      error = true;
      this.setState({ukrainianEnglishValueOnlySpaces: true });
    }
    let labelValue = this.validItemEmpty('labelValue');
    if(/^\s*$/.test(labelValue) && labelValue !== ''){
      error = true;
      this.setState({errorLabelOnlySpaces: true });
    }
    if(!error && englishValue && ukrainianValue && labelValue){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/configs/translations/${that.state.translationID}`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "english": that.state.englishValue,
          "label": that.state.labelValue,
          "translationID": that.state.translationID,
          "translationType": that.state.translationType,
          "ukrainian": that.state.ukrainianValue
        })
      }).then(res => {
        if (res.status === 200) {
          let data = that.state.translationsData;
          data[that.state.translationIndex].ukrainian = that.state.ukrainianValue;
          data[that.state.translationIndex].english = that.state.englishValue;
          data[that.state.translationIndex].label = that.state.labelValue;
          data[that.state.translationIndex].translationType = that.state.translationType;
          that.setState({
            translationsData: data,
            isLoadedSubmit: false,
            englishValue: '',
            labelValue: null,
            translationID: null,
            translationType: null,
            ukrainianValue: '',
            translationIndex: null
          });
          that.handleModalDisabled();
        } else {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              isLoadedSubmit: false
            });
          })
        }
      })
    })
    } else {
      that.setState({
        errorFields: true,
        isLoadedSubmit: false
      });
    }
  }
  handlerSearchField () {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    if(that.state.searchField){
      that.setState({
        translationsData: [],
        isLoaded: false,
        searchEmpty: false
      });
      fetch(`${that.props.queryLink}/bo/v1/configs/translations/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "orderField": "creationDate"})
      }).then(res => {
      if (res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            translationsData: data.data,
            totalPages: data.totalPages,
            isLoaded: true
          });
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            isLoaded: true,
            error: true
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.message,
            isLoaded: true,
            error: true
          });
        })
      }
    })
  }
    that.setState(prevState => ({
      searchField: !prevState.searchField
    }));
  })
  }
  handlerSearch(event){
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + that.props.token()
    });
    if(that.state.timer){
      clearTimeout(that.state.timer);
    }
    that.setState({
      translationsData: [],
      searchValue: event.target.value,
      searchEmpty: false,
      isLoaded: false
    });
    let timer = setTimeout(() => {
      fetch(`${that.props.queryLink}/bo/v1/configs/translations/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "label": that.state.searchValue, "orderField": "creationDate",})
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              translationsData: data.data,
              totalPages: data.totalPages,
              isLoaded: true,
              timer: null
            });
            if(data.data.length < 1){
              that.setState({
                searchEmpty: true
              });
            }
          })
        } else if(res.status === 401) {
          res.json().then(function(data) {
            that.setState({
              errors: [data.message],
              isLoaded: true,
              error: true
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              errors: data.message,
              isLoaded: true,
              error: true
            });
          })
        }
      })
    }, 1000);
    that.setState({
      timer: timer
    });
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }
  handlerTranslationsAddKey(event){
    this.setState({
      valueTranslationsAddKey: event.target.value
    });
  }
  handlerTranslationsAddValue(language, event){
    if(language === 'english'){
      this.setState({
        englishTranslationsAddValue: event.target.value
      });
    } else {
      this.setState({
        ukrainianTranslationsAddValue: event.target.value
      });
    }
  }
  handlerTranslationsAddType(event){
    this.setState({
      valueTranslationsAddType: event.value
    });
  }
  handlerAddTranslatedSubmit(event){
    let that = this;
    let error = false;
    event.preventDefault();
    let location;
    let language = 'uk-UA';
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US'
      }
    }
    that.setState({
      isLoadedSubmit: true,
      errorFields: false,
      errorUkrainianTranslationsOnlySpaces: false,
      englishTranslationsOnlySpaces: false,
      valueTranslationsOnlySpaces: false
    });
    let ukrainianTranslationsAddValue = this.validItemEmpty('ukrainianTranslationsAddValue');
    if(/^\s*$/.test(ukrainianTranslationsAddValue) && ukrainianTranslationsAddValue !== ''){
      error = true;
      this.setState({errorUkrainianTranslationsOnlySpaces: true });
    }
    let englishTranslationsAddValue = this.validItemEmpty('englishTranslationsAddValue');
    if(/^\s*$/.test(englishTranslationsAddValue) && englishTranslationsAddValue !== ''){
      error = true;
      this.setState({englishTranslationsOnlySpaces: true });
    }
    let valueTranslationsAddKey = this.validItemEmpty('valueTranslationsAddKey');
    if(/^\s*$/.test(valueTranslationsAddKey) && valueTranslationsAddKey !== ''){
      error = true;
      this.setState({valueTranslationsOnlySpaces: true });
    }
    let valueTranslationsAddType = that.state.valueTranslationsAddType;
    if(!error && ukrainianTranslationsAddValue && englishTranslationsAddValue && valueTranslationsAddKey && valueTranslationsAddType){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
          'Accept-Language': language
      });
      fetch(`${that.props.queryLink}/bo/v1/configs/translations`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "english": englishTranslationsAddValue,
          "label": valueTranslationsAddKey,
          "translationType": valueTranslationsAddType,
          "ukrainian": ukrainianTranslationsAddValue
        })
      }).then(res => {
        if (res.status === 200) {
          res.json().then(function(data) {
            let arr = that.state.translationsData;
            arr.unshift(data);
            that.setState({
              translationsData: arr,
              isLoadedSubmit: false
            });
          });
          that.handleModalDisabled();
        } else {
          res.json().then(function(data) {
            that.setState({
              errors: data && data.message ? data.message : data,
              isLoadedSubmit: false
            });
          })
        }
      })
    })
    } else {
      that.setState({
        errorFields: true,
        isLoadedSubmit: false
      });
    }
  }
  handlerRemoveModal(id, index, event){
    this.setState({
      modalDelete: true,
      modalDeleteIndex: index,
      modalDeleteId: id
    });
  }
  handleDelete(event){
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/bo/v1/configs/translations/${that.state.modalDeleteId}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                loadingDeleteSumbit: true
              })
            });
        } else {
          that.handleModalDisabled();
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });
          fetch(`${that.props.queryLink}/bo/v1/configs/translations/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "itemsPerPage": 20
            })
          }).then(res => {
            if (res.status === 200) {
              res.json().then(function(data) {
                that.setState({
                  translationsData: data.data,
                  totalPages: data.totalPages,
                  loadingDeleteSumbit: false
                });
              })
            } else if(res.status === 401) {
              res.json().then(function(data) {
                that.setState({
                  errors: [data.message],
                  isLoaded: true,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  errors: data.message,
                  error: true
                });
              })
            }
          })

        }
      })
    })
  }
  render() {
    let translationsDataTable = '';
    if(this.state.error) {
      translationsDataTable = 'Помилка';
    } else if(!this.state.isLoaded){
      translationsDataTable = <Loading />;
    } else {
      translationsDataTable = <table className="data-table">
        <thead>
          <tr>
            <th>Ключ</th>
            <th>English</th>
            <th>Українська</th>
            <th></th>
          </tr>
        </thead>
      <tbody>
      {this.state.translationsData.map((item, index) => {
        return <tr className="standart-table__row" key={index}>
                <td>{item.label}</td>
                <td className="standart-table__translation">{item.english}</td>
                <td className="standart-table__translation">{item.ukrainian}</td>
                <td>
                <div className="team-table__control">
                  <div className="control-wgt">
                    <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                      <svg className="control-btn__icon">
                        <use href={`${sprite}#more-icon`}></use>
                      </svg>
                      </button>
                      <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                        <ul className="control-content__list">
                          <li>
                            <button className="control-content__btn" type="button" onClick={this.handlerGetModal.bind(this, item.translationID, index)}>
                              <svg className="control-content__icon">
                                <use href={`${sprite}#edit-icon`}></use>
                              </svg>Редагувати</button>
                          </li>
                          <li>
                            <button className="control-content__btn control-content__btn_delete" type="button" onClick={this.handlerRemoveModal.bind(this, item.translationID, index)}>
                              <svg className="control-content__icon">
                                <use href={`${sprite}#delete-red`}></use>
                              </svg>Видалення</button>
                          </li>
                        </ul>
                      </div>
                      </div>
                  </div>
                </td>
              </tr>
      })}
      </tbody>
    </table>;
    }
    let typeTranslation = [
      { value: 'BO', label: 'Адмінка компанії' },
      { value:  'ADMIN_PANEL', label: 'Суперадмінка'}
    ];
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      <AccountHeader status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
      <div className="account-wrap account-wrap__admin" onClick={this.handlerCloseElements.bind(this)}>
        <Navigation status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} />
        <div className="account-content">
          <div className="account-content__inner">
            <div className="account-content__header">
              <div className="account-content__header-inner">
                <h1 className="account-content__headline">Переклади</h1>
                <div className="account-content__header-add-button">
                  <button className="add-button" type="button" onClick={this.handlerGetModalCreate.bind(this)}><svg className="add-button__icon"><use href={`${sprite}#plus-icon`}></use></svg></button>
                </div>
                {!this.state.searchField && <button className="search-btn" type="button" onClick={this.handlerSearchField.bind(this)}>
                  <svg className="order-search-icon"><use href={`${sprite}#search-icon`}></use></svg>
                </button>}
              {this.state.searchField && <div className="search-orders-wrap"><form className="search-orders">
                <div className="search-field-wrap">
                  <button className="search-submit" type="submit">
                    <svg className="order-search-icon"><use href={`${sprite}#search-icon`}></use></svg>
                  </button>
                  <button className="search-close" type="button" onClick={this.handlerSearchField.bind(this)}>
                    <svg className="search-close__icon">
                      <use href={`${sprite}#close-icon`}></use>
                    </svg>
                  </button>
                  <input className="search-field" placeholder="Введіть ключ або текст переводу" value={this.state.searchValue} onChange={this.handlerSearch.bind(this)} type="text" />
                </div>
              </form></div>}
              </div>
              <a className="header__language header__language-content" href="#">RU</a>
            </div>
            <div className="account-content__content">
              <div className="account-wgt">
                {translationsDataTable}
              </div>
            </div>
            <div className="account-content__footer">
              <Pagination total={this.state.totalPages} perPage="20" path={`${this.props.queryLink}/bo/v1/configs/translations/search`} currentPage={this.state.currentPage} items={this.handlerPaginationItems} otherParams={{label: this.state.searchValue}} />
            </div>
          </div>
        </div>
      </div>
      {this.state.modal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
          <div className="modal">
            <div className="modal-header">
              <h2 className="modal-headline">{this.state.labelEditModal}</h2>
              <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                <svg className="close-modal__icon">
                  <use href={`${sprite}#close-icon`}></use>
                </svg>
              </button>
            </div>
            <form onSubmit={this.handlerSubmit.bind(this)}>
            <div className="field-wrap">
              <input className={`field-wrap__input ${(this.state.errorFields && !this.state.labelValue) || (this.state.errorFields && this.state.errorLabelOnlySpaces && this.state.labelValue !== '') ? 'field-wrap__input_error' : ''}`} value={this.state.labelValue} onChange={this.handlerKeyValue.bind(this)} placeholder="Введіть ключове слово до перекладу" />
              {this.state.errorFields && !this.state.labelValue ? <div className="validation-error">Обов'язкове поле</div> : ''}
              {this.state.errorFields && this.state.errorLabelOnlySpaces && this.state.labelValue !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
            </div>
            <div className="field-wrap">
              <Select
                styles={customStyles}
                options={typeTranslation}
                onChange={this.handlerTypeValue.bind(this)}
                placeholder="Виберіть куди додавати ключ"
                value={typeTranslation[typeTranslation.findIndex(el => el.value === this.state.translationType)]}
                isSearchable={false}
              />
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label">English</label>
              <textarea className={`field-wrap__input field-wrap__input-area ${(this.state.errorFields && !this.state.englishValue) || (this.state.errorFields && this.state.errorEnglishValueOnlySpaces && this.state.englishValue !== '') ? 'field-wrap__input_error' : ''}`} value={this.state.englishValue} onChange={this.handlerTranslationsValue.bind(this, 'english')} placeholder="Введіть назву" />
              {this.state.errorFields && !this.state.englishValue ? <div className="validation-error">Обов'язкове поле</div> : ''}
              {this.state.errorFields && this.state.errorEnglishValueOnlySpaces && this.state.englishValue !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label">Українська</label>
              <textarea className={`field-wrap__input field-wrap__input-area ${(this.state.errorFields && !this.state.ukrainianValue) || (this.state.errorFields && this.state.ukrainianEnglishValueOnlySpaces && this.state.ukrainianValue !== '') ? 'field-wrap__input_error' : ''}`} value={this.state.ukrainianValue} onChange={this.handlerTranslationsValue.bind(this, 'ukrainian')} placeholder="Введіть назву" />
              {this.state.errorFields && !this.state.ukrainianValue ? <div className="validation-error">Обов'язкове поле</div> : ''}
              {this.state.errorFields && this.state.ukrainianEnglishValueOnlySpaces && this.state.ukrainianValue !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_width_100 btn_size_lg" type="submit" disabled={this.state.isLoadedSubmit ? true : false}>Зберегти{this.state.isLoadedSubmit && <Loading />}</button>
            </div>
          </form>
        </div>
      </div> : ''}

      {this.state.modalCreate ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">Додати ключ та переклад</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <form onSubmit={this.handlerAddTranslatedSubmit.bind(this)}>
            <div className="field-wrap">
              <input className={`field-wrap__input ${(this.state.errorFields && !this.state.valueTranslationsAddKey) || (this.state.errorFields && this.state.valueTranslationsOnlySpaces && this.state.valueTranslationsAddKey !== '') ? 'field-wrap__input_error' : ''}`} value={this.state.valueTranslationsAddKey} onChange={this.handlerTranslationsAddKey.bind(this)} placeholder="Введіть ключове слово до перекладу" />
              {this.state.errorFields && !this.state.valueTranslationsAddKey ? <div className="validation-error">Обов'язкове поле</div> : ''}
              {this.state.errorFields && this.state.valueTranslationsOnlySpaces && this.state.valueTranslationsAddKey !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
            </div>
            <div className="field-wrap">
              <Select
                styles={this.state.errorFields && !this.state.valueTranslationsAddType ? customStylesError : customStyles}
                options={[
                  { value: 'BO', label: 'Адмінка компанії' },
                  { value:  'ADMIN_PANEL', label: 'Суперадмінка'}
                ]}
                onChange={this.handlerTranslationsAddType.bind(this)}
                placeholder="Виберіть куди додавати ключ"
                isSearchable={false}
              />
              {this.state.errorFields && !this.state.valueTranslationsAddType ? <div className="validation-error">Обов'язкове поле</div> : ''}
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label">English</label>
              <textarea className={`field-wrap__input field-wrap__input-area ${(this.state.errorFields && !this.state.englishTranslationsAddValue) || (this.state.errorFields && this.state.englishTranslationsOnlySpaces && this.state.englishTranslationsAddValue !== '') ? 'field-wrap__input_error' : ''}`} value={this.state.englishTranslationsAddValue} onChange={this.handlerTranslationsAddValue.bind(this, 'english')} placeholder="Введіть назву" />
              {this.state.errorFields && !this.state.englishTranslationsAddValue ? <div className="validation-error">Обов'язкове поле</div> : ''}
              {this.state.errorFields && this.state.englishTranslationsOnlySpaces && this.state.englishTranslationsAddValue !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label">Українська</label>
              <textarea className={`field-wrap__input field-wrap__input-area ${(this.state.errorFields && !this.state.ukrainianTranslationsAddValue) || (this.state.errorFields && this.state.errorUkrainianTranslationsOnlySpaces && this.state.ukrainianTranslationsAddValue !== '') ? 'field-wrap__input_error' : ''}`} value={this.state.ukrainianTranslationsAddValue} onChange={this.handlerTranslationsAddValue.bind(this, 'ukrainian')} placeholder="Введіть назву" />
              {this.state.errorFields && !this.state.ukrainianTranslationsAddValue ? <div className="validation-error">Обов'язкове поле</div> : ''}
              {this.state.errorFields && this.state.errorUkrainianTranslationsOnlySpaces && this.state.ukrainianTranslationsAddValue !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_width_100 btn_size_lg" type="submit" disabled={this.state.isLoadedSubmit ? true : false}>Зберегти{this.state.isLoadedSubmit && <Loading />}</button>
            </div>
          </form>
        </div>
      </div> : ''}

      {this.state.modalDelete ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">Видалення перекладу</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="delete-category-form__description">Переклад буде видалено безповоротньо, та відновити його буде неможливо.</div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" type="button" onClick={this.handleDelete.bind(this)} disabled={this.state.loadingDeleteSumbit ? true : false}>Видалити{this.state.loadingDeleteSumbit && <Loading />}</button>
          </div>
          </div>
      </div> : ''}

    </>
  }
}
export default ControlPanelTranslations;
