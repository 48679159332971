import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ResultErrors from './components/ResultErrors';
import Loading from './components/Loading';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import { Navigate } from "react-router-dom";
import Select from 'react-select';
import ResultQuery from './components/ResultQuery';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/cropImage';
import member_1 from './media/avatar-1.png';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
let Editor;
let EditorState;
let convertToRaw;
let ContentState;
let convertFromHTML;
let draftToHtml;
let stateToHTML;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  convertToRaw = require('draft-js').convertToRaw;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;
  draftToHtml = require('draftjs-to-html');
  stateToHTML = require('draft-js-export-html').stateToHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const DragHandle = SortableHandle(() => <button className="categories-faq-wgt__nav-btn categories-faq-wgt__nav_sort" type="button"><svg><use href={`${sprite}#lines-icon`}></use></svg></button>);
const SortableItem = SortableElement(({item, value, handler, categoryInput, newName, handlerNewName, handlerDelete}) => (
  <div className="categories-faq-wgt">
  <div className="categories-faq-wgt__inner">
    {categoryInput === item ? <input className="categories-faq-wgt__input" onChange={handlerNewName.bind(this)} value={newName} /> : <div className="categories-faq-wgt__name">{value}</div>}
    <div className="categories-faq-wgt__nav">
      <button className="categories-faq-wgt__nav-btn" type="button" onClick={handler.bind(this, item, value)}>
        <svg>
          <use href={`${sprite}#edit`}></use>
        </svg>
      </button>
      <button className="categories-faq-wgt__nav-btn" type="button" onClick={handlerDelete.bind(this, item)}>
        <svg>
          <use href={`${sprite}#delete`}></use>
        </svg>
      </button>
      <DragHandle />
    </div>
    </div>

  </div>
));
const SortableList = SortableContainer(({items, handler, categoryInput, newName, handlerNewName, handlerDelete}) => {
  return (
    <div className="categories-faq-list">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} item={index} index={index} value={value.name} handler={handler} categoryInput={categoryInput} newName={newName} handlerNewName={handlerNewName} handlerDelete={handlerDelete} />
      ))}
    </div>)
});
class FaqCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      isLoadedFaq: false,
      isLoadedSubmit: false,
      itemsFaq: [],
      errorFaq: null,
      categoryItems: [],
      modalClass: null,
      modalDisabled: false,
      modal: false,
      modalAddMembers: false,
      modalData: {},
      questionName: '',
      questionDescription: '',
      questionCategory: '',
      isSubmitSuccess: false,
      modalCreateCategory: false,
      modalEditLoading: false,
      categoryNameValue: '',
      contentState: '',
      successSubmit: false,
      successText: '',
      successSubmitCategory: false,
      modalEditCategory: false,
      editCategoryNameInput: null,
      editCategoryNameInputValue: '',
      isLoadedSubmitEditCategory: false,
      successSubmitEdutCategory: false,
      modalDeleteCategory: false,
      errors: null,
      errorFields: false,
      editCategoriesData: [],
      editCategoriesForSubmit: [],
      errorSubmitAccept: false,
      errorSubmitEmpty: false,
      errorOnlySpecial: false,
      errorDescriptionOnlySpace: false,
      categoriesNameLength: false,
      modalAddPhotos: false,
      formDataPhoto: null,
      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1 / 1,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      cropImageStatus: false,
      imagePhotoName: '',
      questionNameLength: false,
      errorLengthDescription: false,
      previewFile: null,
      isLoadingPhoto: false,
      modalPhoto: false,
      errorFormatImgs: false,
      errorSizeImgs: false,
      getAllUsers: false,
      allUsers: false,
      getUsersIds: [],
      usersIds: [],
      employees: [],
      users: [],
      isLoadedUser: false,
      errorUsers: null,
      getUsersIdsHover: []
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalEditCategories: false,
        modalCreateCategory: false,
        modalEditCategory: false,
        modalDeleteCategory: false,
        modalData: {},
        modalPhoto: false,
        formDataLogo: null,
        formData: null,
        imageSrc: null,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1 / 1,
        rotation: 0,
        croppedAreaPixels: null,
        croppedImage: null,
        cropImageStatus: false,
        modalAddMembers: false
      }));
    }, 500);
  }


  onCropChange = (crop) => {
    this.setState({ crop: crop, cropImageStatus: true })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels })
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom: zoom })
  }

  zoomIn = () => {
    let zoomVal = this.state.zoom;
    zoomVal = zoomVal + 0.1;
    this.setState({ zoom: zoomVal })
  };

  zoomOut = () => {
    let zoomVal = this.state.zoom;
    if(zoomVal > 1){
      zoomVal = zoomVal - 0.1;
      this.setState((prevState) => ({
        zoom: zoomVal
      }));
    }
  };

  handlerSearchUsers(event) {
    let arr = this.state.users;
    let newArr = [];
    this.setState({
      searchValue: event.target.value,
      usersSearch: []
    });
    let that = this;
    arr.map(function(item) {
      item.users.map(function(users) {
        if (users.name.includes(event.target.value)) {
           newArr.push(users);
          that.setState(prevState => ({
            usersSearch: [...prevState.usersSearch, users]
          }));
        }
      })
    });
  }
  handlerGetAllUsers(){
    this.setState(prevState => ({
      getAllUsers: !prevState.getAllUsers,
      getUsersIds: [],
    }));
  }
  handlerToggleUser(index, name, id){
    if(this.state.getAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {id: id, name: name}];
    }
    this.setState(prevState => ({
      getUsersIds: arr
    }));
  }
  handlerRemoveAllUsers(){
    this.setState(prevState => ({
      getAllUsers: false,
      allUsers: false
    }));
  }
  handlerRemoveUser(id){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))]
    }
    this.setState(prevState => ({
      getUsersIds: arr,
      usersIDs: ids
    }));
  }
  handlerSetUsers(){
      let ids = [];
      if(this.state.getUsersIds){
        ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
      }
      this.setState(prevState => ({
        allUsers: this.state.getAllUsers ? true : false,
        usersIds: ids
      }));
    this.handleModalDisabled();
  }
  handlerToggleUsersTeam(teamID, event) {
      const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
      const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
      const existingIds = new Set(this.state.getUsersIds.map(user => user.id));
      const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
      let updatedGetUsersIds;
      if (filteredUsers.length > 0) {
          updatedGetUsersIds = this.state.getUsersIds.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
      } else {
          updatedGetUsersIds = [...this.state.getUsersIds, ...newUsers];
      }
      this.setState({ getUsersIds: updatedGetUsersIds });
  }

  handlerHoverEnterUserTeam(teamID, event){
    const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
    const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
    const existingIds = new Set(this.state.getUsersIdsHover.map(user => user.id));
    const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
    let updatedGetUsersIds;
    if (filteredUsers.length > 0) {
        updatedGetUsersIds = this.state.getUsersIdsHover.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
    } else {
        updatedGetUsersIds = [...this.state.getUsersIdsHover, ...newUsers];
    }
    this.setState({ getUsersIdsHover: updatedGetUsersIds });
  }

  handlerHoverLeaveUserTeam(event){
    this.setState({ getUsersIdsHover: [] });
  }

  handlerAddMembers (event) {
    event.preventDefault();
    let that = this;
    if(!event.target.closest('.select-users__label')){
      this.setState({
        modal: true,
        modalAddMembers: true,
        users: [],
        isLoadedUser: false,
        errorUsers: null
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              errorUsers: data.errors,
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              users: data
            })
          });
        }
      })
    })
    }
  }
  setChooseMembers (event) {
    this.handleModalDisabled();
  }

  handlerAddPhotoOpenModal(event) {
    let error = false;
      let files = event.target.files;
      if (files) {
          for (let i = 0; i < files.length; i++) {
              const file = files[i];
              const fileName = file.name.toLowerCase();
              const extension = fileName.split('.').pop();
              const fileSizeInMB = file.size / (1024 * 1024);
              if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
                  error = true;
                  this.setState(prevState => ({
                    errorFormatImgs: true
                  }));
              }
              if (fileSizeInMB > 20) {
                  error = true;
                  this.setState(prevState => ({
                    errorSizeImgs: true
                  }));
              }
          }
          if(!error){
            setTimeout(() => {
                this.setState({
                    imageSrc: URL.createObjectURL(files[0]),
                    imagePhotoName: files[0].name,
                    formDataPhoto: files
                });
                event.target.value = '';
            }, 1000);

            this.setState({
                modalPhoto: true
            });
          }
      }
  }


  handlerAddPhotos(event){
    let that = this;
    this.setState(prevState => ({
      isLoadingPhoto: true
    }));
    if(that.state.cropImageStatus){
      let croppedImage = '';
      croppedImage = getCroppedImg(
        that.state.imageSrc,
        that.state.croppedAreaPixels,
        that.state.rotation
      );
      const myPromise = new Promise((resolve, reject) => {
        const result = croppedImage;
        resolve(result);
      });
      myPromise.then((result) => {
        var img = new Image();
        img.src = result;
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        img.onload = function() {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL('image/jpeg');
          var blob = dataURLToBlob(dataURL);
          const formData = new FormData();
          formData.append('file', blob, that.state.imagePhotoName);
          var file = formData.get('file');
          that.setState(prevState => ({
            previewFile: file,
            formDataForSubmit: formData
          }));
          function dataURLToBlob(dataURL) {
            var byteString = atob(dataURL.split(',')[1]);
            var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], {type: mimeString});
          }
        };
        });
      }
      if(this.state.modalPhoto){
        this.handleModalDisabled();
      }
  }


  handlerEditCategoryName(item, value, event){
    this.setState({
      editCategoryNameInput: item,
      editCategoryNameInputValue: value
    });
  }
  handlerCategoryNameInputValue(event){
    this.setState({
      editCategoryNameInputValue: event.target.value
    });
  }
  handleEditCategories () {
    this.setState(prevState => ({
      modal: !prevState.modal,
      modalEditCategory: true
    }));
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      itemsFaq: arrayMoveImmutable(prevState.itemsFaq, oldIndex, newIndex),
      modalData: {
        title: 'Редактировать категории',
        content: <div>
            <SortableList items={arrayMoveImmutable(prevState.categoryList, oldIndex, newIndex)} onSortEnd={this.onSortEnd} useDragHandle />
          <div className="product-info__nav">
            <div className="product-info__nav-col">
              <button className="btn btn_size_lg btn_width_100 product-info__nav-btn" type="button">Сохранить</button>
            </div>
            <div className="product-info__nav-col">
              <button className="btn btn_theme_gray btn_size_lg btn_width_100 product-info__nav-btn" type="button" onClick={this.handleModalDisabled}>Закрыть</button>
            </div>
          </div>
        </div>
      }
    }));
  }
  handlerSubmitNewNameCategory(event){
    let that = this;
    this.setState({
      modalEditLoading: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories/${that.state.itemsFaq[that.state.editCategoryNameInput].categoryID}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "categoryID": that.state.itemsFaq[that.state.editCategoryNameInput].categoryID,
        "companyID": that.state.companyData.companyID,
        "color": "#000",
        "name": that.state.editCategoryNameInputValue
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            modalEditLoading: false,
            errors: data.errors
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState(prevState => ({
            categoryItems: prevState.categoryItems.map(item => item.value === that.state.itemsFaq[that.state.editCategoryNameInput].categoryID ? {...item, label: that.state.editCategoryNameInputValue} : item),
            itemsFaq: prevState.itemsFaq.map(item => item.categoryID === that.state.itemsFaq[that.state.editCategoryNameInput].categoryID ? {...item, name: that.state.editCategoryNameInputValue} : item),
            modalEditLoading: false,
            successSubmitCategory: true,
            editCategoryNameInput: null,
            editCategoryNameInputValue: '',
            successText: that.props.getTranslate('successStatus-Save')
          }));
          that.handleModalDisabled();
        })
      }
    })
  })
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
      } else {
        res.json().then(function(data) {
          if(data) {
            that.setState({
              companyData: data
            });
            fetch(`${that.props.queryLink}/api/v1/companies/${data.companyID}/categories`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors,
                    isLoadedFaq: true,
                    errorFaq: data.errors
                  });
                })
              } else {
                res.json().then(function(data) {
                  let options = [];
                  data.map((item, index) => {
                    options = [ ...options, {value: item.categoryID, label: item.name} ]
                  });
                  that.setState({
                    categoryItems: options,
                    isLoadedFaq: true,
                    itemsFaq: data
                  });
                })
              }
            })
      }
        })
      }
    })
  })
  }
  handlerQuestionName (event) {
    if(this.state.errorOnlySpecial){
      this.setState({
        errorOnlySpecial: false
      });
    }
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    if(event.target.value.length <= 250){
      this.setState({
        questionName: event.target.value,
        questionNameLength: false
      });
    } else {
      this.setState({
        questionNameLength: true
      });
    }
  }
  handlePastedText = (text, html, editorState) => {
    const contentState = editorState.getCurrentContent();
    const pastedText = ContentState.createFromText(text);

    const totalLength = contentState.getPlainText().length + pastedText.getPlainText().length;

    if (totalLength > 1000) {
      const truncatedText = pastedText.getPlainText();
      const newContentState = ContentState.createFromText(truncatedText);

      const newEditorState = EditorState.push(editorState, newContentState);

      this.onEditorStateChange(newEditorState);
      this.setState({
        errorLengthDescription: true
      });
      return 'handled';
    }

    const newEditorState = EditorState.push(editorState, pastedText);

    this.onEditorStateChange(newEditorState);
    return 'not-handled';
  };
  handleBeforeInput = (chars, editorState) => {
    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText();

    if (text.length >= 1000) {
      this.setState({
        errorLengthDescription: true
      });
      return 'handled';
    }
    return 'not-handled';
  };

  onEditorStateChange = (contentState) => {
    if(this.state.errorLengthDescription){
      this.setState({
        errorLengthDescription: false
      });
    }
    if(this.state.errorDescriptionOnlySpace){
      let s = contentState.getCurrentContent();
      const blocks = s.getBlockMap().toArray();
      const isOnlySpaces = blocks.every(block => block.getText().trim() === '');
      if (!isOnlySpaces) {
        this.setState({
          errorDescriptionOnlySpace: false
        });
      }
    }
    const rawContentState = stateToHTML(contentState.getCurrentContent());
    this.setState({
      contentState: rawContentState
    });
  };
  handlerQuestionCategory = (category) => {
    this.setState({ questionCategory: category.value});
  };
  handlerAddLogo(event){
    let files = event.target.files;
    let formData = this.state.formDataLogo;
    if(formData){
      const dt = new DataTransfer();
      for (let file of formData) {
        dt.items.add(file)
      }
      for (let file of files) {
        dt.items.add(file)
      }
      this.setState(prevState => ({
        formDataLogo: Array.from(dt.files)
      }));
    } else {
      this.setState(prevState => ({
        formDataLogo: Array.from(files)
      }));
    }
  }
  handlerDeleteImg(event){
    this.setState(prevState => ({
      formData: null,
      formDataForSubmit: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1 / 1,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      cropImageStatus: false,
      imagePhotoName: '',
      imageSrc: null,
      formDataPhoto: null,
      formDataLogo: null,
      previewFile: null,
      isLoadingPhoto: false
    }));
  }
  handlerQuestionSubmit (event) {
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      isLoadedSubmit: true,
      errorFields: false,
      errorOnlySpecial: false,
      errorDescriptionOnlySpace: false
    });
    const questionNameWithoutSpaces = this.state.questionName.trim();
    this.setState({
      questionName: questionNameWithoutSpaces
    });
    const isValidQuestionName = /[a-zA-Z0-9а-яА-Я\s]/.test(questionNameWithoutSpaces);
    if(!isValidQuestionName){
      this.setState({
        errorOnlySpecial: true
      });
    }
    let editor = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(that.state.contentState)
      )
    );
    const contentState = editor.getCurrentContent();
    const blockKeys = contentState.getBlockMap().keySeq();
    let onlySpaces = true;
    blockKeys.forEach((blockKey) => {
      const block = contentState.getBlockForKey(blockKey);

      const text = block.getText();

      if (text.trim() !== '') {
        onlySpaces = false;
      }
    });
    if (onlySpaces) {
      this.setState({
        errorDescriptionOnlySpace: true
      });
    }
    if(questionNameWithoutSpaces === '' || !isValidQuestionName || Object.keys(this.state.contentState).length === 0 || onlySpaces || !this.state.questionCategory){
      error = true;
    }
    if(!error){

      if(that.state.formDataForSubmit){
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Authorization': 'Bearer ' + idToken
        });
          fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes/photos`, {
            method: 'POST',
            headers: myHeaders,
            body: that.state.formDataForSubmit
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                })
              })
            } else {
              const myHeaders = new Headers({
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + idToken
              });
              res.json().then(function(data) {
                fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes`, {
                  method: 'POST',
                  headers: myHeaders,
                  body: JSON.stringify({
                    "companyCategoryID": that.state.questionCategory,
                    "description": that.state.contentState,
                    "name": that.state.questionName,
                    "photoID": data.entityID,
                    "allUsers": that.state.getAllUsers,
                    "employees": that.state.usersIds
                  })
                }).then(res => {
                  if (res.status !== 200) {
                    that.setState({
                      isLoadedSubmit: false
                    });
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      })
                    });
                  } else {
                      if(typeof window !== "undefined" && typeof document !== 'undefined'){
                        localStorage.setItem("successFaqCreate", JSON.stringify(true))
                      }
                      that.setState({
                        isLoadedSubmit: false,
                        isSubmitSuccess: true,
                        successSubmit: (res) ? true : false
                      });
                  }
                })
              });
              }
          })
        })
      } else {
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "companyCategoryID": that.state.questionCategory,
            "description": that.state.contentState,
            "name": that.state.questionName,
            "allUsers": that.state.getAllUsers,
            "employees": that.state.usersIds
          })
        }).then(res => {
          if (res.status !== 200) {
            that.setState({
              isLoadedSubmit: false
            });
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                localStorage.setItem("successFaqCreate", JSON.stringify(true))
              }
              that.setState({
                isLoadedSubmit: false,
                isSubmitSuccess: true,
                successSubmit: (res) ? true : false
              });
          }
        })
      })
      }
    } else {
      that.setState({
        isLoadedSubmit: false,
        errorFields: true
      });
    }
  }
  handlerSubmitCreateCategory(event){
    event.preventDefault();
    let that = this;
    this.setState({
      modalEditLoading: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "color": "#000",
        "name": that.state.categoryNameValue
      })
    }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          });
        } else {
          res.json().then(function(data) {
            let optionsNew = {value: data.categoryID, label: data.name};
            that.setState(prevState => ({
              itemsFaq: [...prevState.itemsFaq, data],
              modalEditLoading: false,
              categoryItems: [ ...prevState.categoryItems, optionsNew ],
              categoryNameValue: '',
              successSubmitCategory: true,
              successText: that.props.getTranslate('successStatus-Save')
            }));
          })
          that.handleModalDisabled();
        }
    })
  })
  }
  handlerCategoryNameValue(event){
    this.setState({
      categoryNameValue: event.target.value
    });
  }
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSubmit: false,
      successSubmitCategory: false,
      successText: ''
    }));
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerDeleteCategory(item, event){
    this.handleModalDisabled();
    setTimeout(() => {
      this.setState(prevState => ({
        editCategoryNameInput: item,
        modalDeleteCategory: true
      }));
    }, 500);
  }
  hanlderSubmitDeleteCategory (event) {
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedSubmitCategoryEdit: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories/${that.state.itemsFaq[that.state.editCategoryNameInput].categoryID}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedSubmitCategoryEdit: false,
            errors: data.errors
          });
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedSubmitCategoryEdit: false
          });
        })
        fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/notes/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "companyID": that.state.companyData.companyID,
            "itemsPerPage": 20
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                editCategoryNameInput: null,
                itemsFaq: data
              });
            })
            that.handleModalDisabled();
          }
        })
      }
    })
  })
  }

  handlerOpenEditCategories(){
    let that = this;
    this.setState(prevState => ({
        modalEditCategories: true
    }));
        getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {

        });
      } else {
        res.json().then(function(data) {
          that.setState(prevState => ({
            editCategoriesData: data
          }));
        })
      }
    })
  })
  }
  handlerCreateNewCategory(event){
    this.setState(prevState => ({
      editCategoriesData: [{"color": "#000", "name": "", status: true}, ...prevState.editCategoriesData]
    }));
  }
  handlerEditCategoriesValue(index, event){
    let categories = this.state.editCategoriesData;
    let value = event.target.value;
    if(value.length <= 40){
      categories[index].name = value;
    }
    if(categories[index].empty || categories[index].special){
      delete categories[index].empty;
      delete categories[index].special;
    }
    this.setState(prevState => ({
      editCategoriesData: categories
    }));
  }
  handlerSetStatusEditCategory(index, event){
    let categories = this.state.editCategoriesData;
    categories[index].status = true;
    this.setState(prevState => ({
      editCategoriesData: categories
    }));
  }
  handlerSetAcceptStatusCategory(index, event){
    let emptyError = false;
    let specialError = false;
    let categories = this.state.editCategoriesData;
    delete categories[index].empty;
    delete categories[index].special;
    let name = categories[index].name.trim();
    const isValidCurrencyName = /[a-zA-Z0-9а-яА-Я\s]/.test(name);
    if(name === ''){
      emptyError= true;
      categories[index].empty = true;
    }
    if(!isValidCurrencyName){
      specialError= true;
      categories[index].special = true;
    }
    if(!emptyError && !specialError){
      delete categories[index].status;
      delete categories[index].empty;
      delete categories[index].special;
      categories[index].submit = true;
    }
    this.setState(prevState => ({
      editCategoriesData: categories
    }));
  }
  handlerSubmitEditCategories(event){
    let that = this;
    let emptyError = false;
    let specialError = false;
    let errorSubmitAccept = false;
    this.setState({
      modalEditLoading: true,
      errorSubmitAccept: false
    });
    let categories = this.state.editCategoriesData;
    categories.map((item, index) => {
      let name = item.name.trim();
      const isValidCurrencyName = /[a-zA-Z0-9а-яА-Я\s]/.test(name);
      if(name === ''){
        emptyError= true;
        categories[index].empty = true;
        this.setState({
          modalEditLoading: false
        });
        return false;
      }
      if(!isValidCurrencyName){
        specialError= true;
        categories[index].special = true;
        this.setState({
          modalEditLoading: false
        });
        return false;
      }
      if(item.status){
        errorSubmitAccept = true;
        this.setState({
          modalEditLoading: false,
          errorSubmitAccept: true
        });
        return false;
      }
    });
    if(!emptyError && !specialError && !errorSubmitAccept){
          let submitCategories = categories.filter(element => element.submit);
          if(submitCategories.length > 0){
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      async function asyncAwaitWay(that) {
        for (let category of submitCategories) {
          if(category.categoryID){
          fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories/${category.categoryID}`, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
              "categoryID": category.categoryID,
              "color": category.color,
              "companyID": category.companyID,
              "name": category.name
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              submitCategories.shift();
              if(submitCategories.length < 1){
                that.setState({
                  modalEditLoading: false
                });
                that.handleModalDisabled();
            fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors,
                    isLoadedFaq: true,
                    errorFaq: data.errors
                  });
                })
              } else {
                res.json().then(function(data) {
                  let options = [];
                  data.map((item, index) => {
                    options = [ ...options, {value: item.categoryID, label: item.name} ]
                  });
                  that.setState({
                    categoryItems: options,
                    isLoadedFaq: true,
                    itemsFaq: data
                  });
                })
              }
            })
              }
            }
          })
          } else {
          fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "color": '#000',
              "name": category.name
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  modalEditLoading: false
                });
              })
            } else {
              submitCategories.shift();
              if(submitCategories.length < 1){
                that.setState({
                  modalEditLoading: false
                });
                that.handleModalDisabled();
            fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors,
                    isLoadedFaq: true,
                    errorFaq: data.errors
                  });
                })
              } else {
                res.json().then(function(data) {
                  let options = [];
                  data.map((item, index) => {
                    options = [ ...options, {value: item.categoryID, label: item.name} ]
                  });
                  that.setState({
                    categoryItems: options,
                    isLoadedFaq: true,
                    itemsFaq: data
                  });
                })
              }
            })
              }
            }
          })
          }
        }
      }
      asyncAwaitWay(that);
    })
  } else {
        this.setState({
      modalEditLoading: false
    });
    that.handleModalDisabled();
  }
  }
  }
  handlerDeleteCategoryByIdModal(id, index, event){
    let that = this;
    that.setState({
      idDeleteCategory: id ? id : 'empty',
      indexDeleteCategory: index
    });
    if(id){
      that.handleModalDisabled();
      setTimeout(function() {
        that.setState({
          modal: true,
          modalDeleteCategory: true
        });
      }, 500);
    } else {
      let categories = that.state.editCategoriesData;
      categories.splice(index, 1);
        that.setState(prevState => ({
          editCategoriesData: categories
        }));
    }
  }
  handlerDeleteCategoryById(event){
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/companies/${that.state.companyData.companyID}/categories/${that.state.idDeleteCategory}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          });
      } else {
        let categories = that.state.editCategoriesData;
        categories.splice(that.state.indexDeleteCategory, 1);
          that.setState(prevState => ({
            editCategoriesData: categories
          }));
      }
    })
      })

  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('onboarding-CreateTitle') + ' | ' + this.props.companyName;
    }
    if(this.state.successSubmitCategory){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }

    let chooseUsers = '';
    if(this.state.usersIds.length > 0 && !this.state.allUsers){
      chooseUsers = this.state.getUsersIds.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.name}<span onClick={this.handlerRemoveUser.bind(this, item.id)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span></span>
      })
    }

    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ?       <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div></Scrollbars> : <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        <div className={`${this.state.getAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">{this.props.getTranslate('modalAddUser-AllUsersLabel')}</span>
          </div>
          {this.state.getAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label" onClick={this.handlerToggleUsersTeam.bind(this, item.teamID)} onMouseEnter={this.handlerHoverEnterUserTeam.bind(this, item.teamID)} onMouseLeave={this.handlerHoverLeaveUserTeam.bind(this)}>{item.name ? item.name : this.props.getTranslate('modalAddUser-NoTeamLabel')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            let hoverActive = this.state.getUsersIdsHover && this.state.getUsersIdsHover.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
              return <div className={`${this.state.getAllUsers || active || hoverActive ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {this.state.getAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }

    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {this.state.errors ? <div className="response-submit">
    {this.state.errors.map((item, index) => {
      return <div key={index} className="response-submit__item response-submit__item_error">{item}
            <button className="response-submit__close" type="button" onClick={this.handlerCloseError.bind(this)}>
            <svg><use href={`${sprite}#close-icon`}></use></svg>
            </button></div>
    })}</div>: ''}
    <ResultQuery success={this.state.successSubmitCategory} resultText={this.state.successText} handlerSuccess={this.handlerCloseSuccess} handlerError={this.handlerCloseError} />
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      {this.state.successSubmit && <Navigate to={this.props.getPermalink("/account/faq")} replace={true} />}
      {this.state.isLoadedFaq ? <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
            backBtn={true}
            backBtnType="link"
            backBtnClass="button-back_theme_gray"
            backBtnHandler={this.props.getPermalink("/account/faq")}
            backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
          />

              <div className="account-content__header-title">
                <h1 className="account-content__headline">{this.props.getTranslate('onboarding-CreateTitle')}</h1>
              </div>
            <div className="accrual-form-wrap">
              <form className="question-form" onSubmit={this.handlerQuestionSubmit.bind(this)}>
              <div className="accrual-form__inner">
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="name">{this.props.getTranslate('onboarding-Caption')}</label>
                  <input id="name" className={`field-wrap__input field-wrap__input_width_75 ${(this.state.errorFields && !this.state.questionName) || (this.state.errorFields && this.state.errorOnlySpecial && this.state.questionName !== '') || this.state.questionNameLength ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.questionName} onChange={this.handlerQuestionName.bind(this)} placeholder={this.props.getTranslate('onboarding-CaptionFieldCaption')} />
                  {this.state.errorFields && !this.state.questionName ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {this.state.errorFields && this.state.errorOnlySpecial && this.state.questionName ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
                  {this.state.questionNameLength ? <div className="validation-error">{this.props.getTranslate('validation-QuestionNameLength')}</div> : ''}
                </div>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('onboarding-Description')}</label>
                  {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                    toolbar={{
                      options: ['inline', 'blockType', 'list'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline']
                      },
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered']
                      }
                    }}
                    wrapperClassName={`wrapper-class editor-field ${this.state.errorDescriptionOnlySpace || this.state.errorLengthDescription ? 'field-wrap__input_error' : ''}`}
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class editor-toolbar"
                    handleBeforeInput={this.handleBeforeInput}
                    handlePastedText={this.handlePastedText}
                    onEditorStateChange={this.onEditorStateChange}
                  /> : ''}
                  {this.state.errorDescriptionOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {this.state.errorLengthDescription ? <div className="validation-error">{this.props.getTranslate('validation-errorLengthDescriptionOnboarding')}</div> : ''}
                </div>
<div className="board-form__row">
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('onboarding-CategoryName')}</label>
                  {this.state.categoryItems && this.state.categoryItems.length > 0 ? <Select
                    styles={this.state.errorFields && !this.state.questionCategory ? customStylesError : customStyles}
                    options={this.state.categoryItems}
                    isSearchable={false}
                    onChange={this.handlerQuestionCategory.bind(this)}
                    placeholder={this.props.getTranslate('onboarding-CategoryPlaceholder')}
                  /> : <div onClick={this.handlerOpenEditCategories.bind(this)}> <Select
                    styles={this.state.errorFields && !this.state.questionCategory ? customStylesError : customStyles}
                    options={this.state.categoryItems}
                    isSearchable={false}
                    placeholder={this.props.getTranslate('onboarding-CategoryPlaceholder')}
                    isDisabled={true}
                  /></div> }
                  {this.state.errorFields && !this.state.questionCategory ? <div className="validation-error">{this.props.getTranslate('validation-RequiredCategory')}</div> : ''}
                  <button className="library-form__categories-edit account-wgt__edit-btn" type="button" onClick={this.handlerOpenEditCategories.bind(this)}>{this.props.getTranslate('bunusesForm-EditCategoriesBtn')}</button>
                  </div>
                </div>
                </div>
                <div className="event-form__row">
                  <div className="field-wrap field-wrap__members">
                    <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('events-Members')}</label>
                    <div className={`select-modal ${this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? 'field-wrap__input_error' : ''}`} onClick={this.handlerAddMembers.bind(this)}>
                    {this.state.usersIds.length < 1 ?
                    <div className="select-users__input">
                      {this.state.allUsers && this.state.usersIds.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                                <use href={`${sprite}#close-icon`}></use>
                                              </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('getEmployees-FieldSelectPlaceholder')}</div>}
                    </div> : <div className="select-users__input">{chooseUsers}</div>}
                    </div>
                    {this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  </div>
                </div>
                <div className="edit-user-form__imgs">
                  <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('onboarding-Photo')}</label>
                  <div className="edit-user-form__imgs-inner">
                    <div className={`edit-user-upload${this.state.errorFormatImgs || this.state.errorSizeImgs ? ' edit-user-upload-error' : ''}`}>
                      <input type="file" id="upload" onChange={this.handlerAddPhotoOpenModal.bind(this)} />
                      <label htmlFor="upload">
                        <svg className="upload__icon">
                          <use href={`${sprite}#img-upload`}></use>
                        </svg>{this.state.formDataForSubmit ? this.props.getTranslate('loadingImg-ChangePhoto') : this.props.getTranslate('loadingImg-LoadPhoto')} {this.state.formData && <span style={{marginTop: '5px'}}>{this.props.getTranslate('loadingImg-AmountTitle')} {this.state.formData.length}</span>}</label>
                    </div>
                    {this.state.formData && this.state.isLoadedSubmit ? <div className="edit-user-upload">
                      <div className="edit-user-upload__percent">
                        <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                        <div className="edit-user-upload__bar">
                          <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                        </div>
                      </div>
                    </div> : ''}
                    {this.state.formDataForSubmit ? <div className="edit-product-img"><div><button className="edit-product-img__remove" type="button" onClick={this.handlerDeleteImg.bind(this)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img className="sort-img" src={URL.createObjectURL(this.state.previewFile)} alt="" /></div></div> : ''}
                  </div>
                  {this.state.errorFormatImgs ? <div className="validation-error">{this.props.getTranslate('validation-errorFormatImages')}</div> : ''}
                  {this.state.errorSizeImgs ? <div className="validation-error">{this.props.getTranslate('validation-OnboardingImgSize')}</div> : ''}
                </div>
                <div className="field-wrap__submit question-form__submit">
                  <button className="btn btn_width_100 btn_size_lg field-wrap__submit-send" type="submit" disabled={this.state.isLoadedSubmit ? true : false}>{this.props.getTranslate('onboarding-CreateButton')}{this.state.isLoadedSubmit && <Loading />}</button>
                </div>
              </form>
            </div>
          </div>
        </div> : <div className="account-content"><Loading /></div>}
    </div>
    {this.state.modalDeleteCategory ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-deleteOnboardingTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <form className="delete-category-form" onSubmit={this.hanlderSubmitDeleteCategory.bind(this)}>
        <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteOnboardingDescription')}</div>
        <div className="user-info__nav">
          <div className="user-info__nav-col field-wrap__submit">
            <button className="btn btn_size_lg btn_theme_red btn_width_100 user-info__nav-btn" type="submit" disabled={this.state.isLoadedSubmitCategoryEdit ? true : false}>{this.props.getTranslate('onboarding-Delete')}{this.state.isLoadedSubmitCategoryEdit && <Loading />}</button>
          </div>
          <div className="user-info__nav-col">
            <button className="btn btn_theme_gray btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handleModalDisabled}>{this.props.getTranslate('onboarding-CloseButton')}</button>
          </div>
        </div>
      </form>
    </div>
    </div> : ''}

          {this.state.modalEditCategories ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('onboarding-EditTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div>
          <div className="edit-category__add-category">
            <button className="btn btn_width_100 btn_theme_transparent btn_size_lg" onClick={this.handlerCreateNewCategory.bind(this)}>{this.props.getTranslate('onboarding-AddCategory')}</button>
          </div>
          {this.state.editCategoriesData ? <>
            <Scrollbars
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                  renderTrackVertical={props => <div {...props} className="track-vertical-scroll track-vertical" />}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                  renderThumbVertical={props => <div {...props} className="thumb-vertical-scroll thumb-vertical"/>}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={300}
                    renderView={props => <div {...props} />}>
                    <div style={{paddingRight: '15px'}}>
          {this.state.editCategoriesData.map((item, index) => {
            return <div className="categories-faq-wgt" key={index}>
                      <div className="categories-faq-wgt__inner">
                        {item.status ? <input className={`categories-faq-wgt__input ${item.empty || (item.special && !item.empty) || item.name.length >= 100 ? 'field-wrap__input_error' : ''}`} value={item.name} onChange={this.handlerEditCategoriesValue.bind(this, index)} placeholder={this.props.getTranslate('onboarding-CategoryNamePlaceholder')} /> : <div className="categories-faq-wgt__name">{item.name}</div>}
                        <div className="categories-faq-wgt__nav">
                        {item.status ? <button className={`categories-faq-wgt__nav-btn categories-faq-wgt__nav-btn-add`} type="button" onClick={this.handlerSetAcceptStatusCategory.bind(this, index)}>
                          <svg>
                            <use href={`${sprite}#check-icon`}></use>
                          </svg>
                        </button> : ''}
                          {!item.status ? <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerSetStatusEditCategory.bind(this, index)}>
                            <svg>
                              <use href={`${sprite}#edit`}></use>
                            </svg>
                          </button> : ''}
                          <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerDeleteCategoryByIdModal.bind(this, item.categoryID, index)}>
                            <svg className={`${item.categoryID ? 'categories-faq-wgt__nav-btn-delete' : 'categories-faq-wgt__nav-btn-close'}`}>
                              <use href={`${sprite}#${item.categoryID ? 'delete' : 'close-icon-category'}`}></use>
                            </svg>
                          </button>
                        </div>
                        </div>
                          {item.name.length >= 100 && item.status ? <div className="validation-error">{this.props.getTranslate('validation-categoryNameLengthField')}</div> : ''}
                          {item.empty ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                          {item.special && !item.empty ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
                      </div>
          })}</div></Scrollbars></> : ''}
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100 product-info__nav-btn" type="button" onClick={this.handlerSubmitEditCategories.bind(this)} disabled={this.state.modalEditLoading}>{this.props.getTranslate('onboarding-SaveButton')}{this.state.modalEditLoading ? <Loading /> : ''}</button>
              </div>
          </div>
        </div>
        </div> : ''}


        {this.state.modalPhoto ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal modal__product-img">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modal-onboardingImgEditTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>

          <><div className="modal__img-setting-wrap">
            <div className="modal__img-setting-inner">
              {this.state.imageSrc && this.state.formDataPhoto ? <Cropper
                image={this.state.imageSrc}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
                showGrid={true}
                minZoom={1}
              /> : <Loading />}
              <div className="modal__img-zoom-btns">
                <button className="modal__img-zoom-btn" onClick={this.zoomIn} type="button">+</button>
                <button className="modal__img-zoom-btn" onClick={this.zoomOut} type="button">-</button>
              </div>
            </div>
          </div></>
          <button className="btn btn_size_lg btn_width_100" type="button" onClick={this.handlerAddPhotos.bind(this)}>{this.props.getTranslate('modal-onboardingImgEditBtn')}</button>
        </div>
        </div> : ''}

        {this.state.modal && this.state.modalAddMembers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div className="field-wrap field-wrap__search-members">
            <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
            <svg className="field-wrap__search-icon">
              <use href={`${sprite}#search-icon`}></use>
            </svg>
          </div>
          <div className="question-members">
            <div className="question-members-wgt">
              {users}
            </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
            </div>
          </div>
        </div>
      </div> : ''}

    </>;

  }
}
export default FaqCreate;
